import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Container, Button } from "react-bootstrap"
import { buildUrl } from '../helper/handler'
import TabellaOrganizzazioni from './TabellaOrganizzazioni';
import { useLocation } from 'react-router-dom';
import { getFromApi } from '../utilities/NetUtilities';

function Table() {

    const [datiTable, setDatiTable] = useState({ dati: [] });
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [typeMessage, setTypeMessage] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setShowAlert(true)
            setMessage(location.state.message)
            setTypeMessage('primary')
        }

        const okAction = (data) => {
            const res = data.response
            setDatiTable({
                dati: res
            })
        }

        const errorAction = () => {
            const errore = "Non è stato possibile visualizzare la tabella, se il problema persiste contattare l'assistenza"
            setMessage(errore)
            setShowAlert(true)
            setTypeMessage('warning')
        }
        const getDati = () => {
            const url = buildUrl('gestioneorganizzazioni')
            getFromApi(url, okAction, errorAction);
        }
        getDati();
    }, [location])

    const handleCloseAlert = () => {
        setShowAlert(false)
    }


    return (
        <div className='main' role="main" onClick={handleCloseAlert}>
            <Container id="organizzazioni" className='space'>
                <Alert id="message" show={showAlert} transition={null} variant={typeMessage}>{message}</Alert>
                <h1 id='pagina_gestione_organizzazioni' className="d-flex justify-content-center space">Organizzazioni</h1>
                <Link to="/addorganizzazione">
                    <Button id="addOrganizzazione" className='btnGenerale' variant="flat" type="button" size="sm">Aggiungi</Button>
                </Link>
                {!datiTable.dati.length ? <div><p className='space'>Non ci sono organizzazioni</p></div> :
                    <div>
                        <TabellaOrganizzazioni datiTabella={datiTable} setMessage={setMessage} setShowAlert={setShowAlert} ></TabellaOrganizzazioni>
                        <Link to="/addorganizzazione"><Button className='btnGenerale' variant="flat" type="button" size="sm">Aggiungi</Button></Link>
                    </div>
                }
            </Container>
        </div>
    )

}

export default Table;
