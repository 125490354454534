import React, { useEffect, useState } from 'react'
import { Container, Alert } from "react-bootstrap";
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';
import moment from "moment";
import FiltriReport from './FiltriReport';
import TabellaSede from './TabellaSede';
import TabellaServizio from './TabellaServizio';
import TabellaLavoratore from './TabellaLavoratore';

function ReportOrarioLavoro() {

    const [message, setMessage] = useState([])
    const [inizio, setInizio] = useState(null)
    const [fine, setFine] = useState(null)
    const [sede, setSede] = useState(null);
    const [lavoratore, setLavoratore] = useState(null);
    const [tabellaSede, setTabellaSede] = useState(null)
    const [minutiTotSede, setMinutiTotSede] = useState(null)
    const [tabellaServizio, setTabellaServizio] = useState(null)
    const [minutiTotServizio, setMinutiTotServizio] = useState(0)
    const [tabellaLavoratore, setTabellaLavoratore] = useState(null)
    const [totMinutiLavoratore, setTotMinutiLavoratore] = useState(null)
    const [sedi, setSedi] = useState([]);
    const [lavoratori, setLavoratori] = useState([])
    const [urlCsvTabellaZona, setUrlCsvTabellaZona] = useState("")
    const [urlCsvTabellaLavoratore, setUrlCsvTabellaLavoratore] = useState("")
    const [urlCsvTabellaServizio, setUrlCsvTabellaServizio] = useState("")

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setLavoratoriFromApi = (data) => {
            setLavoratori(data.lavoratori.map(lavoratore => ({
                "value": lavoratore.id,
                "label": lavoratore.nomeCognome
            })));
        }
        const setSediFromApi = (data) => {
            setSedi(data.sedi.map(sede => ({
                "value": sede.id,
                "label": sede.descrizione
            })));
        }
        const setMessageFromApi = (data) => {
            setMessage(message => [...message, { tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione }])
        }
        if (organizzazioneMap) {
            const urlLavoratori = buildUrl(
                'lavoratori',
                {
                    organizzazione: organizzazioneMap.nome,
                    tipo: organizzazioneMap.tipo
                });
            getFromApi(urlLavoratori, setLavoratoriFromApi, setMessageFromApi);
            getFromApi(buildUrl('sedi', { organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo }), setSediFromApi, setMessageFromApi);
        }
    }, [])

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setTabelle = (data) => {
            setTabellaSede(data.tabellasede);
            setMinutiTotSede(data.totminutisede);
            setTabellaServizio(data.tabellaservizio);
            setTabellaLavoratore(data.tabellalavoratore);
            setMinutiTotServizio(data.totminutiServizio);
            setTabellaLavoratore(data.tabellalavoratore);
            setTotMinutiLavoratore(data.totminutilavoratore)
        }
        const setMessageFromApi = (data) => {
            setMessage(message => [...message, { tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione }])
        }
        if (organizzazioneMap && inizio && fine) {
            const params = {
                organizzazione: organizzazioneMap.nome,
                tipo: organizzazioneMap.tipo,
                inizio: inizio,
                fine: fine,
                sede: sede ? sede.value : "",
                lavoratore: lavoratore ? lavoratore.value : ""
            };
            const url = buildUrl('report', params);
            getFromApi(url, setTabelle, setMessageFromApi);
            var paramsZona = params;
            var paramsLavoratore = params;
            var paramsServizio = params;
            setUrlCsvTabellaZona(buildUrl('downloadReportCsv', Object.assign(paramsZona, {nometabella: "zona"})));
            setUrlCsvTabellaLavoratore(buildUrl('downloadReportCsv', Object.assign(paramsLavoratore, {nometabella: "lavoratore"})));
            setUrlCsvTabellaServizio(buildUrl('downloadReportCsv', Object.assign(paramsServizio, {nometabella: "servizio"})));
        }

    }, [inizio, fine, sede, lavoratore])

    return (

        <div className='main' role="main">
            <div>
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Report</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id="message" key={key}>
                            {data.descrizione}
                        </Alert>
                    ))}
                <Container className="">
                    <FiltriReport setMessage={setMessage} setInizio={setInizio} setFine={setFine} setLavoratore={setLavoratore} setSede={setSede} sedi={sedi} lavoratori={lavoratori} sede={sede} lavoratore={lavoratore} />
                </Container>
                <Container fluid>
                    <div className='row space'>
                        <div className="col-lg-4 col-md-4">
                            <TabellaSede tabellaSede={tabellaSede} minutiTotSede={minutiTotSede} urlCsv={urlCsvTabellaZona}/>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <TabellaLavoratore tabellaLavoratore={tabellaLavoratore} totMinutiLavoratore={totMinutiLavoratore}  urlCsv={urlCsvTabellaLavoratore}/>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <TabellaServizio tabellaServizio={tabellaServizio} minutiTotServizio={minutiTotServizio}  urlCsv={urlCsvTabellaServizio}/>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ReportOrarioLavoro;