import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillEdit } from "react-icons/ai";
import'../css/App.css';

function TabellaOrganizzazioni(props) {
    
    const renderTableData = () => {
        return props.datiTabella.dati.map((organizzazione, index) => {
            const { nome, tipo, zone, applicativi } = organizzazione
            const zoneList = Object.values(zone);
            let sortedZoneList = zoneList.sort(
                (p1, p2) => (p1.codice < p2.codice) ? -1 : (p1.codice > p2.codice) ? 1 : 0);
            let zoneNames = sortedZoneList.map((zona, index) => {
                return <li className='ml-5' key={index}> Nome: {zona.descrizione} - Codice: {zona.codice} - Esclusa: {zona.esclusa ? 'SI' : 'NO'}</li>;
            })
            let applicativiList = applicativi.map((applicativo, index) => {
                return <li className='ml-5' key={index}> {applicativo}</li>;
            })
            return (
                <tr className="tableOrganizzazione" key={index}>
                    <td className='text-center'>{nome}</td>
                    <td className='text-center'>{tipo.toLowerCase() }</td>
                    <td><ul>{zoneNames}</ul></td>
                    <td><ul>{applicativiList}</ul></td>
                    <td className='App'> <Link id={"organizzazione-" + index} to={{pathname: `/editorganizzazione`, search:`nome=${nome}&tipo=${tipo}` }}>
                        <button className='aclibutton' title='Modifica organizzazione'><AiFillEdit className='aclieditbutton'></AiFillEdit></button>
                        </Link> </td>
                </tr>
            )
        })
    }

    return (
        <>
            <table id='tabellaorganizzazioni' className="table table-bordered">
                <tbody>
                    <tr className="App tableOrganizzazione">
                        <th>Nome</th>
                        <th>Tipo</th>
                        <th>Zone</th>
                        <th>Applicativi</th>
                        <th>Modifica</th>
                    </tr>
                    {renderTableData()}
                </tbody>
            </table>
        </>
    )
}

export default TabellaOrganizzazioni;
