import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Row, Col, Alert } from 'react-bootstrap'
import { buildUrl } from './helper/handler'
import { getFromApi, postToApi } from './utilities/NetUtilities';

function Obiettivo() {

    const [message, setMessage] = useState({})
    const [obiettivi, setObiettivi] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("")
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("")

    const okAction = (data) =>{
        setObiettivi(data.obiettivi.reverse())
    }
    const nokAction = (data) => {
        setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione });
        setShowAlert(true);        
    }
    useEffect(() => {
        const init = () => {
            const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"))
            setNomeOrganizzazione(organizzazioneMap.nome)
            setTipoOrganizzazione(organizzazioneMap.tipo)
            const paramsObject = {
                organizzazione: organizzazioneMap.nome,
                tipo: organizzazioneMap.tipo,
                tutti: false
            }
            const url = buildUrl('obiettivi', paramsObject)
            getFromApi(url, okAction, nokAction);
        }
        init();
    }, [])

    const eliminaActionOk = (data) => {
        setMessage({ tipo: "primary", descrizione: data.messaggi[0].descrizione });
        setShowAlert(true)

    }
    const eliminaActionNok = (data) => {
        setMessage({ tipo: data.messaggi[0].descrizione.tipo, desrcizione: data.messaggi[0].descrizione });
        setShowAlert(true)

    }

    const updateObiettivo = e => {
        e.preventDefault();
        const params = {
            organizzazione: nomeOrganizzazione,
            obiettivi: obiettivi
        }
        const url = buildUrl('saveobiettivi');
        postToApi(url, params, eliminaActionOk, eliminaActionNok);
    }

    const CaricaTutti = e => {
        e.preventDefault();
        const paramsObject = {
            organizzazione: nomeOrganizzazione,
            tipo: tipoOrganizzazione,
            tutti: true
        }
        const url = buildUrl('obiettivi', paramsObject)
        getFromApi(url, okAction, nokAction);
    }

    return (
        <div className='main2' role='main' onClick={() => setShowAlert(false)}>
            <Container fluid>
                <Alert variant={message.tipo} id="message" show={showAlert} className='mt-3'>
                    {message.descrizione}
                </Alert>
                <h1 className="d-flex justify-content-center">Obiettivi
                    ({nomeOrganizzazione.toLowerCase()}/{tipoOrganizzazione.toLowerCase()})</h1>
                <div className='mt-3'>
                    <Form id="form" data-testid="form" onSubmit={updateObiettivo}>
                        {obiettivi.map((obiettivo, key) => {
                            return (
                                <Row key={key}>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>anno</Form.Label>
                                            <Form.Control type="text" id={'anno-' + key} aria-label={'anno-' + key} value={obiettivo.anno} disabled className='input-lg' />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>obiettivo (%)</Form.Label>
                                            <Form.Control type="number" id={'obiettivo-' + key} aria-label={'obiettivo-' + key} value={obiettivo.obiettivo}
                                                onChange={e => {
                                                    const newState = obiettivi.map(obj => {
                                                        if (obj.anno === obiettivo.anno) {
                                                            return { ...obj, obiettivo: parseFloat(e.target.value) };
                                                        }
                                                        return obj;
                                                    });
                                                    setObiettivi(newState)
                                                }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Button className="btnGenerale float-right" variant="flat" size="sm" type="submit" id="edit" data-testid="edit">Modifica</Button>
                        <Button className="btnGenerale float-right" variant="flat" size="sm" type="button" id="tutti"
                            data-testid="tutti" onClick={CaricaTutti}>Carica tutti</Button>
                    </Form>
                </div>
            </Container>
        </div>
    )
}

export default Obiettivo


