import { buildUrl } from "../helper/handler"

export const buildAreeDaAbbinare = (mapDati) => {

    // costruzione opzioni Aree (730, IMU, VARIE .....)
    const optionsMacroAree = []
    Object.keys(mapDati).forEach((macroarea) => {
        if (macroarea !== "area_da_abbinare") {
            const option = {}
            option.label = macroarea
            option.value = macroarea
            optionsMacroAree.push(option)
        }
    })

    // costruzione list elementi aree ancora da abbinare
    const list = []
    mapDati.area_da_abbinare.forEach(area => {
        area.ricavi.forEach((element) => {
            const codice_ricavo = element.codiceRicavo
            const descrizioneCR = element.descrizioneCodiceRicavo
            const areaDaAbbinare = {}
            areaDaAbbinare.name = descrizioneCR
            areaDaAbbinare.codice = codice_ricavo
            areaDaAbbinare.value = optionsMacroAree
            list.push(areaDaAbbinare)
        })
    });
    return list
}

export const buildSottoAreeDaAbbinare = (mapDati) => {

    const optionsMacroAree = []
    Object.keys(mapDati).forEach((macroarea) => {
        if (macroarea !== "area_da_abbinare") {
            const option = {}
            option.label = macroarea
            option.value = macroarea
            optionsMacroAree.push(option)
        }
    })


    const aree = []
    Object.keys(mapDati).forEach((macroarea) => {
        if (macroarea !== "area_da_abbinare") {
            const area = {}
            area.name = macroarea
            const associati = {}
            const listSottoareeDaAssociare = []
            const listAreePersonalizzati = []
            const sottoareaAssociati = {}
            mapDati[macroarea].forEach((sottoarea) => {
                if (sottoarea.ricavi.length >0) {
                    sottoarea.ricavi.forEach((e) => {
                        const codice_ricavo = e.codiceRicavo
                        const descrizioneCR = e.descrizioneCodiceRicavo
                        const areaAbbinati = {}
                        areaAbbinati.name = descrizioneCR
                        areaAbbinati.codice = codice_ricavo
                        areaAbbinati.selected = {label:macroarea, value:macroarea}
                        associati[codice_ricavo] = areaAbbinati
                    })
                }
            })
            mapDati[macroarea].forEach((sotto) => {
                if (sotto.nome !== "sottoarea_da_abbinare") {
                    const specificaArea = {}
                    specificaArea.label = sotto.nome
                    specificaArea.value = sotto.nome
                    listAreePersonalizzati.push(specificaArea)
                    if (sotto.ricavi.length > 0) {
                        const listSottoareeAssociati = []
                        sotto.ricavi.forEach((sottoarea) => {
                            sottoarea.tariffe.forEach((tariffario) => {
                                const sottoareaAssociata = {}
                                var desTariffa = tariffario.descrizioneTariffa
                                if (tariffario.descrizioneTariffa === "") {
                                    desTariffa = "DESCRIZIONE VUOTA (" + sottoarea.descrizioneCodiceRicavo + ")"
                                }
                                sottoareaAssociata.descrizione = desTariffa
                                sottoareaAssociata.selected = {label: sotto.nome, value: sotto.nome}
                                listSottoareeAssociati.push(sottoareaAssociata)
                            })
                        })
                        sottoareaAssociati[sotto.nome] = listSottoareeAssociati
                    }

                }
                if (sotto.nome === "sottoarea_da_abbinare" && sotto.ricavi.length > 0) {
                    sotto.ricavi.forEach((sottoarea) => {
                        const codiceRC = sottoarea.codiceRicavo
                        sottoarea.tariffe.forEach((tariffario) => {
                            const sottoareaDaAssociare = {}
                            sottoareaDaAssociare.codiceRt = tariffario.codiceTariffa
                            var desTariffa = tariffario.descrizioneTariffa
                            if (tariffario.descrizioneTariffa === "") {
                                desTariffa = "DESCRIZIONE VUOTA (" + sottoarea.descrizioneCodiceRicavo + ")"
                            }
                            sottoareaDaAssociare.descrizione = desTariffa
                            sottoareaDaAssociare.options = listAreePersonalizzati
                            sottoareaDaAssociare.codiceRc =codiceRC
                            listSottoareeDaAssociare.push(sottoareaDaAssociare)
                        })
                    })

                }
            })

            area.associati = associati
            area.list_sottoaree_da_associare = listSottoareeDaAssociare
            area.sottoaree_associati = sottoareaAssociati
            area.show = false
            if (Object.keys(associati).length > 0 || 
                    listSottoareeDaAssociare.length > 0 || Object.keys(sottoareaAssociati).length >0) {
                area.show = true
            }
            aree.push(area)
        }
    })
    return aree
}
