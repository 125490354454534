import React, { useEffect, useState, useRef } from 'react'
import { buildUrl } from '../helper/handler'
import { Form, Card, Container, Row, Col, Button, Alert } from "react-bootstrap"
import { getUserFromStorage } from '../helper/handler'
import { MDBDataTable } from "mdbreact";
import Select from 'react-select'
import '../css/App.css';
import { AiFillEdit } from "react-icons/ai";
import { getFromApi , postToApi} from '../utilities/NetUtilities';


function Users() {

    const [data, setData] = useState("")
    const [userToEdit, setUserToEdit] = useState()
    const [organizzazioneSelected, setOrganizzazioneSelected] = useState("")
    const [tipoUserSelected, setTipoUserSelected] = useState({ value: "operatore", label: "operatore" })
    const refEdit = useRef(null);
    const [updatedMessage, setUpdatedMessage] = useState({})
    const [inputDisabled, setInputDisabled] = useState("disabled")
    const [action, setAction] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const [listaOrganizzazioni, setListaOrganizzazioni] = useState([]);
    const [tipoUsers, setTipoUsers] = useState([])

    const [userLogged, setUserLogged] = useState()

    useEffect(() => {
        const getOrganizzazioni = () => {
            const url = buildUrl('listorganizzazioni')
            getFromApi(url, okGetOrganizzazioniAction);       
        }
        const getUser = () => {
            const user = getUserFromStorage()
            setUserLogged(user)
            getListUsers(user, setTipoUsers, buildTableRows, setData, setUpdatedMessage);
        }
        getUser();
        getOrganizzazioni();
    }, [setUpdatedMessage])

    const handleCloseAlert = () => {
        setUpdatedMessage({})
    }

    const okGetOrganizzazioniAction = (data) => {
        const datiOrg = data.response
        setListaOrganizzazioni(datiOrg)
    }


    const updateSeletions =  (event) => {
        setOrganizzazioneSelected(event)
        const organizzazioni = []
        Object.values(event).forEach(option => {
            const organizzazione = {}
            organizzazione.nome = option.value
            organizzazione.tipo = option.type
            organizzazione.value = option.label
            organizzazioni.push(organizzazione)
        })
        const updatedValue = { organizzazioni: organizzazioni }
        setUserToEdit(userToEdit => ({
            ...userToEdit,
            ...updatedValue
        }));
    }

    const updateTipoUserSelected = (event) => {
        setTipoUserSelected(event)
        const updatedValue = { role: event.value }
        setUserToEdit(userToEdit => ({
            ...userToEdit,
            ...updatedValue
        }));
    }

    const updateUsernameToSend = (event) => {
        const updatedValue = { username: event.target.value }
        setUserToEdit(userToEdit => ({
            ...userToEdit,
            ...updatedValue
        }));
    }

    const editUserGet = (event) => {
        setAction("modifica")
        const split = event.role.props.children.split("_")
        var ruoloStr = ""
        split.forEach((e) => {
            ruoloStr += e + " "
        })
        setUserToEdit({
            username: event.username.props.children,
            role: event.role.props.children,
            organizzazioni: event.organizzazione_pulito
        })
        const selectedValues = []
        event.organizzazione_pulito.forEach((organizzazione) => {
            selectedValues.push({ value: organizzazione.nome, label: organizzazione.nome + "/" + organizzazione.tipo.toLowerCase(), type: organizzazione.tipo })
        })
        setOrganizzazioneSelected(selectedValues)
        setTipoUserSelected([{ value: event.role.props.children.toLowerCase(), label: ruoloStr }])
        refEdit.current?.scrollIntoView({ behavior: "smooth" })
    }

    const submitEditUser = e => {
        e.preventDefault();

        const okConfermaEditUser = (data) => {
            getListUsers(userLogged, setTipoUsers, buildTableRows, setData, setUpdatedMessage);
            setUpdatedMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
        }
        const nokConfermaEditUser = (data) => {
            setUpdatedMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
        }
        const url = buildUrl('edituserconfirm')
        const request = {
            username: userToEdit.username,
            role: userToEdit.role,
            organizzazioni: userToEdit.organizzazioni
        }
        postToApi(url, request, okConfermaEditUser, nokConfermaEditUser);
    }

    const buildTableRows = (response) => {
        const datiUsers = {
            columns: [{
                label: "Username",
                field: "username",
                sort: "asc",
                width: 150,
            },
            {
                label: "Ruolo",
                field: "role",
                sort: "asc",
                width: 200
            },
            {
                label: "Organizzazioni",
                field: "organizzazioni",
                sort: "asc",
                width: 100
            },
            {
                label: "Modifica",
                field: "modifica",
                sort: "asc",
                width: 100
            }]
        }
        const rows = []
        Object.values(response).forEach((dati, index) => {
            const row = {}
            // row.username = <p onClick={() => editUser(dati)} style={{color:"#006e8f ", cursor: "pointer"}} >{dati.username}</p>
            row.username = <span id={'username-' + index} data-testid={'username-' + index}>{dati.username}</span>
            row.role = <span id={'role-' + index} data-testid={'role-' + index}>{dati.role.toLowerCase()}</span>
            var list = ""
            var listPulito = []
            dati.organizzazioni.forEach((organizzazione, index) => {
                var organizzazioneUser = {}
                organizzazioneUser.nome = organizzazione.nome
                organizzazioneUser.tipo = organizzazione.tipo
                listPulito.push(organizzazioneUser)
                if (index === 0) {
                    list += organizzazione.nome + '/' + organizzazione.tipo.toLowerCase()
                }
                if (index > 0) {
                    list += " - " + organizzazione.nome + '/' + organizzazione.tipo.toLowerCase()
                }
            })
            row.organizzazioni = <span id={'organizzazioni-' + index} data-testid={'organizzazioni-' + index}>{list}</span>
            row.organizzazione_pulito = listPulito
            row.modifica = <span id={'modifica-' + index} className="d-flex justify-content-center">
                <button className='aclibutton' title='Modifica utente'><AiFillEdit className='aclieditbutton'></AiFillEdit></button></span>
            row.clickEvent = (event) => editUserGet(event)
            rows.push(row)
        })
        datiUsers.rows = rows
        return datiUsers
    }

    const addUser = () => {
        setInputDisabled("")
        setTipoUserSelected([{ value: "operatore", label: "operatore" }])
        setOrganizzazioneSelected([])
        setUserToEdit({ username: "", role: "operatore", organizzazioni: [] })
        setAction("aggiungi")
    }

    const okConfirmAddUser = (data) => {
        getListUsers(userLogged, setTipoUsers, buildTableRows, setData, setUpdatedMessage);
        setUpdatedMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
    }

    const nokConfirmAddUser = (data) => {
        setUpdatedMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
    }

    const confirmAddUser = e => {
        e.preventDefault();
        const updatedValue = { tipo: "warning", descrizione: "" }
        setUpdatedMessage(updatedMessage => ({
            ...updatedMessage,
            ...updatedValue
        }));
        const url = buildUrl('registration')
        const request = {
            username: userToEdit.username,
            role: userToEdit.role,
            organizzazioni: userToEdit.organizzazioni
        }
        postToApi(url, request, okConfirmAddUser, nokConfirmAddUser);
    }

    const handleClose = () => {
        setIsOpen(!isOpen);
    };    
    return (
        <div className='main' role='main'>
            <Container onClick={handleCloseAlert}>
                <h1 className="d-flex justify-content-center space" id="pagina_gestione_utenti">gestione utenti</h1>
                {userLogged && userLogged.role === "SUPER_AMMINISTRATORE" && <span className='btn-add-user'>
                    <Button size="sm" onClick={addUser} id="addUser" data-testid="addUser">aggiungi</Button>
                </span>}
                {data &&
                    <MDBDataTable
                        id="listUtenti"
                        striped
                        bordered
                        hover
                        data={data}
                        entries={5}
                        entriesOptions={[5, 10, 20]}
                        searchLabel="Cerca"
                        entriesLabel="elementi per pagina"
                        paginationLabel={["Precedente", "Successiva"]}
                        infoLabel={["da", "a", "su", "utenti totali"]}
                        searching={false}
                    />}
                <span ref={refEdit}>
                    <Alert variant={updatedMessage.tipo} id="message" className='mt-3'>
                        {updatedMessage.descrizione}
                    </Alert>
                    {userToEdit &&
                        <Card style={{ marginBottom: "100px" }}>
                            <Card.Body>
                                <Form onSubmit={submitEditUser} id="form" data-testid="form">
                                    <Row>
                                        <Col>
                                            <Form.Group className='ml-3'>
                                                <Form.Label>email</Form.Label>
                                                <Form.Control type="email" disabled={inputDisabled}
                                                    data-testid="input-username" id="input-username" value={userToEdit.username}
                                                    aria-label='Modifica email utente' onChange={updateUsernameToSend} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='ml-3'>
                                                <Form.Label>ruolo</Form.Label>
                                                {/* <Form.Control type="text" data-testid="ruolo" id="ruolo"  value={userToEdit.ruolo}/> */}
                                                <Select value={tipoUserSelected} data-testid="input-role" id="input-role"
                                                    aria-label='Modifica ruolo utente' options={tipoUsers} onChange={updateTipoUserSelected}></Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className='ml-3'>
                                                <Form.Label>organizzazioni</Form.Label>

                                                <Select isMulti value={organizzazioneSelected}
                                                    options={listaOrganizzazioni}
                                                    aria-label='Modifica organizzazioni utente' onChange={updateSeletions} data-testid="input-organizzazioni" id="input-organizzazioni"></Select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <div className='mt-3 p-3 float-right'>
                                                {action === "modifica" && <>
                                                    {userLogged && userLogged.role === "SUPER_AMMINISTRATORE" &&
                                                        <Button variant="danger" size="md" type="button" id="delete" data-testid="delete" onClick={handleClose}>
                                                            Elimina
                                                        </Button>}
                                                    <Button className='btnGenerale' variant="flat" size="md" type="submit" id="edit" data-testid="edit">Modifica</Button>
                                                </>}
                                                {action === "aggiungi" &&
                                                    <Button variant="primary" size="md" id="conferma" data-testid="conferma" onClick={confirmAddUser}>conferma</Button>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>}
                </span>
            </Container>
        </div>
    )

}

export default Users


function getListUsers(user, setTipoUsers, buildTableRows, setData, setUpdatedMessage) {
    const okListUserAction = (data) => {
        setTipoUsers(data.tipoutenti)
        const datiUsers = buildTableRows(data.response);
        setData(datiUsers);
    }
    const nokListUserAction = (data) => {
        setUpdatedMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione });
    }
    const params = {
        username: user.username
    }
    const url = buildUrl('listutenti', params)
    getFromApi(url, okListUserAction, nokListUserAction);   
}
