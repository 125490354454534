import React, { useEffect, useState } from 'react'
import { Form, Container, Row, Col, Button, Alert } from "react-bootstrap"
import Select from 'react-select'
import '../css/App.css';
import { getFromApi } from '../utilities/NetUtilities';
import { buildUrl } from "../helper/handler"
import {
    buildAreeDaAbbinare,
    buildSottoAreeDaAbbinare
} from './handlerAssociazioneAree'


export function AssociazioneArea() {

    const [listAreeDaAbbinare, setListAreeDaAbbinare] = useState([])
    const [aree, setAree] = useState([])
    const [nuoveSottoAree, setNuoveSottoAree] = useState({})
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("")
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("")

    const [message, setMessage] = useState({})


    useEffect(() => {
        const okAction = (data) => {
            const list = buildAreeDaAbbinare(data.response)
            setListAreeDaAbbinare(list)
            const aree = buildSottoAreeDaAbbinare(data.response)
            setAree(aree)

        }
        const nokAction = (data) => {
            setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
            window.scrollTo(0, 0)
        }
        const getDati = () => {
            const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"))
            setNomeOrganizzazione(organizzazioneMap.nome)
            setTipoOrganizzazione(organizzazioneMap.tipo)
            const params = {
                organizzazione: organizzazioneMap.nome,
                tipo: organizzazioneMap.tipo
            }
            const url = buildUrl('gestioneassociazioniaree', params)
            getFromApi(url, okAction, nokAction);
        }
        getDati();
    }, [])

    const okAssegnaSezioneAction = (data) => {
        const list = buildAreeDaAbbinare(data.response)
        setListAreeDaAbbinare(list)
        const aree = buildSottoAreeDaAbbinare(data.response)
        setAree(aree)
    }
    const nokAssegnaSezioneAction = (data) => {
        setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
        window.scrollTo(0, 0)
    }
   


    const assegnaSezione = (event, codice_ricavo) => {
        const paramsObject = {
            organizzazione: nomeOrganizzazione,
            tipo: tipoOrganizzazione,
            area: event.value,
            codice_ricavo: codice_ricavo
        }
        const url = buildUrl('assegnaArea', paramsObject)
        getFromApi(url, okAssegnaSezioneAction, nokAssegnaSezioneAction);
    }

    const okAssegnaSottoSezioneAction = (data) => {
        const list = buildAreeDaAbbinare(data.response)
        setListAreeDaAbbinare(list)
        const aree = buildSottoAreeDaAbbinare(data.response)
        setAree(aree)
        setMessage({tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
    }

    const nokAssegnaSottoSezioneAction = (data) => {
        setMessage({tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
        window.scrollTo(0, 0)
    }

    const assegnaSottoSezione = (event, areaname, codice_ricavo, codice_tafiffario, descrizione) => {
        const params = {
            organizzazione: nomeOrganizzazione,
            tipo: tipoOrganizzazione,
            area: areaname,
            sottoarea_spe: event.value,
            codice_ricavo: codice_ricavo,
            codice_tariffa: codice_tafiffario,
            descrizione: descrizione
        }
        const url = buildUrl('updatesottoarea', params)
        getFromApi(url, okAssegnaSottoSezioneAction, nokAssegnaSottoSezioneAction);
    }

    const addNuoveSottoAreeName = (e, areaname) => {
        e.preventDefault();
        const update = {}
        update[areaname] = e.target.value
        setNuoveSottoAree(nuoveSottoAree => ({
            ...nuoveSottoAree,
            ...update
        }));
    }

    const addSottoArea = (e, areaname) => {
        const okaddSottoAreaAction = (data) => {
            const list = buildAreeDaAbbinare(data.response)
            setListAreeDaAbbinare(list)
            const aree = buildSottoAreeDaAbbinare(data.response)
            setAree(aree)
            setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
            const update = {}
            update[areaname] = ""
            setNuoveSottoAree(nuoveSottoAree => ({
                ...nuoveSottoAree,
                ...update
            }));
        }
        const nokaddSottoAreaAction = (data) => {
            setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione })
            window.scrollTo(0, 0)
        }
        const params = {
            organizzazione: nomeOrganizzazione,
            tipo: tipoOrganizzazione,
            area: areaname,
            sottoarea_spe: nuoveSottoAree[areaname]
        }
        const url = buildUrl('addsottoarea', params)
        getFromApi(url, okaddSottoAreaAction, nokaddSottoAreaAction);

     /*    const result = addNuovaSottoArea(nomeOrganizzazione, tipoOrganizzazione, areaname, nuoveSottoAree[areaname])

        result.then(data => {
            var objectData = JSON.parse(data);
            if (objectData.response) {
                const list = buildAreeDaAbbinare(objectData.response)
                setListAreeDaAbbinare(list)
                const aree = buildSottoAreeDaAbbinare(objectData.response)
                setAree(aree)
                if (objectData.message) {
                    setMessage({ tipo: "info", descrizione: objectData.message })
                    const update = {}
                    update[areaname] = ""
                    setNuoveSottoAree(nuoveSottoAree => ({
                        ...nuoveSottoAree,
                        ...update
                    }));
                }
            } else {
                setMessage({ tipo: "danger", descrizione: objectData.errore })
                window.scrollTo(0, 0)
            }
        }); */
    }

    return (
        <div className='main' role='main'>
            <Alert variant={message.tipo} id="message">
                {message.descrizione}
            </Alert>
            <h1 className="d-flex justify-content-center mt-1 mb-3" id="title_assoc_area">Associazione area ({nomeOrganizzazione.toUpperCase()})</h1>
            <Container className='space'>
                {listAreeDaAbbinare.length > 0 && <div>
                    <div className='border mt-3' id="sezione_macro_da_abbinare">
                        <h2 className='mt-4 ml-3 mb-5'>Ricavi da abbinare :</h2>
                        {listAreeDaAbbinare && listAreeDaAbbinare.map((areaDA, index) => {
                            return (
                                <div className='m-3' key={index}>
                                    <Row>
                                        <Col xs={1}></Col>
                                        <Col>
                                            <label
                                                htmlFor={"area_da_abbinare_select_" + index}
                                                className="text-right"
                                                id={"area_da_abbinare_text_" + index}>{areaDA.name}
                                            </label>
                                        </Col>
                                        <Col>
                                            <Select
                                                data-cy={"area_da_abbinare_select_" + index}
                                                id={"area_da_abbinare_select_" + index}
                                                className="mb-1"
                                                value={{ label: " ----- ", value: "" }}
                                                options={areaDA.value}
                                                aria-label={areaDA.name}
                                                onChange={(e) => assegnaSezione(e, areaDA.codice)}></Select>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </div></div>
                }

                {aree.length > 0 && <div>
                    {/* <h2 className='mt-3'>Aree</h2> */}

                    {aree && aree.map((area, indexArea) => {
                        return (
                            <div key={area.name + indexArea}>
                                {area.show && <>
                                    <div className='border mt-3'>
                                        <div style={{ width: "100%" }} id={"sezione_" + area.name}>
                                            <div className='areaTitle'>
                                                <Row><Col><h3 className='mt-3 ml-3'>Area {area.name}: </h3></Col></Row>
                                                <Row>
                                                    <Col xs={2}></Col>
                                                    <Col>
                                                        <div className='' id={"sezione_" + area.name + "_area_abbinati"}>
                                                            {Object.values(area.associati).map((associato, index) => {
                                                                return (
                                                                    <div className='mt-3' key={area.name + "_area_abbinati" + index}>

                                                                        <Form.Group>
                                                                            <label
                                                                                htmlFor={area.name + "_area_associato_text_" + index}
                                                                                className="text-right"
                                                                                id={area.name + "_area_associato_text_" + index}>
                                                                                {associato.name}
                                                                            </label>
                                                                            {/* <Select id={area.name + "_area_associato_select_" + index} className="mb-3" value={associato.selected}
                                                                options={associato.value} onChange={(e) => assegnaSezione(e, associato.codice)}></Select> */}
                                                                        </Form.Group>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='border' style={{ width: "100%" }}></div>
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className='mt-3'>
                                                            <label className="ml-3 mr-3">Nuova sotto area</label>
                                                            <input
                                                                id={"input_nuovo_sottoarea_" + area.name}
                                                                type="text"
                                                                value={nuoveSottoAree[area.name]}
                                                                aria-label='Nuova sotto area'
                                                                onChange={(e) => addNuoveSottoAreeName(e, area.name)}>
                                                            </input>
                                                            <Button
                                                                id={"aggiungi_" + area.name + "_" + indexArea}
                                                                type="submit"
                                                                className='btnGenerale'
                                                                variant="flat" size="sm"
                                                                onClick={(e) => addSottoArea(e, area.name)}>
                                                                aggiungi
                                                            </Button>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </>
                                            <div className='border' style={{ width: "100%" }}></div>
                                            <div><h4 className='mt-3 ml-3 mb-4'>Tariffe da abbinare :</h4></div>
                                            <div xs={8} className='m-3' id={"sezione_" + area.name + "_sottoaree_da_abbinare"}>
                                                {area.list_sottoaree_da_associare.map((sottoareaDa, index) => {
                                                    return (
                                                        <Row key={area.name + "_sottoaree_da_abbinare" + index}>
                                                            <Col xs={1}></Col>
                                                            <Col xs={8}>
                                                                <label
                                                                    htmlFor={area.name + "_sottoareada_desc_" + index}
                                                                    className="text-right"
                                                                    id={area.name + "_sottoareada_text" + index}>
                                                                    {sottoareaDa.descrizione}
                                                                </label>
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    data-cy={area.name + "_sottoareada_desc_" + index}
                                                                    id={area.name + "_sottoareada_desc_" + index}
                                                                    className="mb-3"
                                                                    options={sottoareaDa.options}
                                                                    value={{ label: " ----- ", value: "" }}
                                                                    aria-label={sottoareaDa.descrizione}
                                                                    onChange={(e) =>
                                                                        assegnaSottoSezione(
                                                                            e, area.name,
                                                                            sottoareaDa.codiceRc,
                                                                            sottoareaDa.codiceRt,
                                                                            sottoareaDa.descrizione)}>
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </div>
                                            <div className='border' style={{ width: "100%" }}></div>
                                            <div id={"sezione_" + area.name + "_sottoaree_abbinati"}>
                                                <h4 className='m-3 mb-4'>Tariffe abbinate:</h4>
                                                {Object.keys(area.sottoaree_associati).map((sottoarea_asso, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Row className='m-3'>
                                                                <Col xs={3}><h5 className='mr-1 text-right'>{sottoarea_asso} :</h5></Col>
                                                                <Col>
                                                                    {area.sottoaree_associati[sottoarea_asso].map((associati, indexSotto) => {
                                                                        return (
                                                                            <div className='' key={area.name + "_sottoaree_abbinato" + indexSotto}>
                                                                                <Col>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Group>
                                                                                                <label
                                                                                                    className="text-right"
                                                                                                    id={area.name + "_sottoare_desc_" + index}>
                                                                                                    {associati.descrizione}
                                                                                                </label>
                                                                                                {/* <Select className="mb-3" options={associati.options} value={associati.selected}
                                                                                        id = {area.name + "_sottoare_select_" + index}
                                                                                        onChange={(e) => assegnaSottoSezione(e, area.name, associati.codiceRc, associati.codiceRt)}></Select> */}
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </>}</div>
                        )
                    })}
                </div>}
            </Container>
        </div>
    )
}