import Select from 'react-select'
import React, { useState, useEffect } from 'react'
import { Form, Alert, Container, Col, Button, Row } from "react-bootstrap"
import { buildUrl, getUserFromStorage } from '../helper/handler'
import { useLocation } from 'react-router-dom';
import { getFromApi , postToApi} from '../utilities/NetUtilities';

function AddEditOrganization(props) {

    const [nome, setNome] = useState('');
    const [dicitura730Congiunto, setDicitura730Congiunto] = useState('');
    //const [dicituraOriginale, setDicituraOriginale] = useState('');
    const [titolo, setTitolo] = useState('');
    const [idTitolo, setIdTitolo] = useState('');
    const [idFunzione, setIdFunzione] = useState('');
    const [disabled, setDisabled] = useState("");
    const [api, setApi] = useState('');
    const [zone, setZone] = useState([])
    const [zoneOriginale, setZoneOriginale] = useState([])
    const [user, setUser] = useState();
    const [applicativi, setApplicativi] = useState([]);
    const [applicativiOriginale, setApplicativiOriginale] = useState([])
    const [listaApplicativi, setListaApplicativi] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [tipoOrganizzazione, setTipiOrganizzazione] = useState([])
    const [tipoSelected, setTipoSelected] = useState({})

    // const history = useHistory();
    const location = useLocation()

    useEffect(() => {

        const okAction = (data) => {
            const datiOrg = data.response
            const applicativiOrg = data.applicativi
            setDati(datiOrg, applicativiOrg)
        }
        const nokAction = (data) => {
            setMessage({ tipo: data.messaggi[0].descrizione.tipo, desrcizione: data.messaggi[0].descrizione });
        }
        const getDati = () => {
            const query = new URLSearchParams(location.search);
            const nomeOrg = query.get('nome')
            const tipoOrg = query.get('tipo')
            const params = {
                nome: nomeOrg,
                tipo: tipoOrg
            }
            const url = buildUrl('selezionaorganizzazione', params)
            getFromApi(url, okAction, nokAction);
        }
        const okGetAplicativiAction = (data) => {
            const applicativi = data.response
            const tipiOrganizzazione = data.tipiorganizzazione
            setListaApplicativi(applicativi)
            setTipiOrganizzazione(tipiOrganizzazione)
        }

        const getApplicativi = () => {
            const url = buildUrl('addorganizzazioneget')
            getFromApi(url, okGetAplicativiAction);
        }

        setUser(getUserFromStorage());
        getApplicativi();
        if (props.edit) {
            setTitolo("Modifica organizzazione")
            setIdTitolo("modifica_organizzazione");
            setIdFunzione("modifica");
            setDisabled("disabled")
            setApi("editorganizzazione");
            getDati();
        } else {
            setTitolo("Aggiungi organizzazione")
            setIdTitolo("aggiungi_organizzazione");
            setIdFunzione("aggiungi");
            setApi("addorganizzazione");
        }
    }, [props.edit, location.search])

    const setDati = (dati, applicativi) => {
        setNome(dati.nome)
        var tipoOrganizzazione = { label: dati.tipo, value: dati.tipo }
        setTipoSelected(tipoOrganizzazione)
        const zoneOrg = Object.values(dati.zone)
        setZone(zoneOrg)
        setZoneOriginale(zoneOrg)
        setApplicativi(applicativi)
        var sorted = applicativi.sort((a, b) => a.value.localeCompare(b.value, 'es', { sensitivity: 'base' }))
        setApplicativiOriginale(sorted)
        setDicitura730Congiunto(dati.dicitura730Congiunto)
    }

    const updateSelections = (event) => {
        setApplicativi(event)
        checkApplicativiIsToUpdate(event)
    }

    const checkApplicativiIsToUpdate = (data) => {
        if (props.edit) {
            data.sort((a, b) => a.value.localeCompare(b.value, 'es', { sensitivity: 'base' }))
            if (Object.values(data).length !== Object.values(applicativiOriginale).length ||
                JSON.stringify(data) !== JSON.stringify(applicativiOriginale)) {
                setMessage({tipo:"info", descrizione:"ci sono modifiche da salvare"})
                setShowAlert(true)
            } else {
                setShowAlert(false)
            }
        }
    }

    const handleZona = (event, index) => {
        let data = [...zone];
        data[index][event.target.name] = event.target.value;
        setZone(data);
    }

    const handleZonaCheckbox = (event, index) => {
        let data = [...zone];
        data[index].esclusa = event.target.checked;
        setZone(data);
    }

    const removeZona = (index) => {
        let data = [...zone];
        data.splice(index, 1)
        setZone(data)
        checkZoneIsToUpdate(data)
    }

    const checkZoneIsToUpdate = (data) => {
        if (props.edit) {
            if (Object.values(data).length !== Object.values(zoneOriginale).length) {
                setMessage({tipo:"info", descrizione:"ci sono modifiche da salvare"})
                setShowAlert(true)
            } else {
                setShowAlert(false)
            }
        }
    }

    const addZona = () => {
        let object = {
            codice: '',
            descrizione: ''
        }
        setZone([...zone, object])
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
        checkZoneIsToUpdate([...zone, object])
    }

    const submitOrganization = (e) => {
        e.preventDefault();
        try {
            if (nome) {
                if (tipoSelected) {
                    const organizzazione = buildOrganizzazione(nome, tipoSelected, zone, applicativi, dicitura730Congiunto)
                    if (checkZone(zone)) {
                        addEditOrganizzazione(organizzazione)
                    } else {
                        throw Error("I valori delle zone non può essere vuoto");
                    }
                    window.scroll(0, 0)
                } else {
                    throw Error("Il parametro 'tipo' è obbligatorio");
                }
            } else {
                throw Error("Il parametro 'nome' è obbligatorio");
            }
        } catch (e) {
            setMessage({tipo:"warning", descrizione: e.message})
            setShowAlert(true)
            window.scroll(0, 0)
        }
    }

    function checkZone(zone) {
        var check = true;
        Object.values(zone).forEach(zona => {
            if (zona.codice === "") {
                check = false;
            }
        })
        return check;
    }

    function addEditOrganizzazione(organizzazione) {
        const addEditAction = (data) => {
            setMessage(data.messaggi[0])
            setShowAlert(true)
            if (!props.edit) {
                window.scroll(0, 0)
            }
        }
        const params = {
            username: user.username
        }
        const url = buildUrl(api, params)
        const request = {
            nome: organizzazione.nome,
            tipo: organizzazione.tipo,
            zone: organizzazione.zone,
            applicativi: organizzazione.applicativi,
            dicitura730Congiunto: organizzazione.dicitura730Congiunto
        };
        postToApi(url, request, addEditAction, addEditAction);
    }

    function buildOrganizzazione(nome, tipo, zone, applicativi, dicitura730Congiunto) {
        const organizzazione = {};
        organizzazione.nome = nome;
        organizzazione.tipo = tipo.value;
        organizzazione.zone = zone;
        organizzazione.applicativi = applicativi;
        organizzazione.dicitura730Congiunto = dicitura730Congiunto;
        return organizzazione;
    }


    return (
        <div className='main' role='main'>
            <div className='alertOrganizzazione'>
                <Alert id="message" show={showAlert} transition={null} variant={message.tipo} >{message.descrizione}</Alert>
            </div>
            <h1 className="d-flex justify-content-center space m-3" id={idTitolo}>{titolo}</h1>
            <Container className="space justify-content-center">

                <Form onSubmit={submitOrganization} id="form" data-testid="form">
                    <div className='border mb-3'>
                        <Form.Group className='ml-5 mr-5 mt-5'>
                            <Form.Label>Nome organizzazione</Form.Label>
                            <Form.Control type="text" value={nome}
                                data-testid="input-nome" id="input-nome" aria-label="Nome organizzazione" onChange={e => setNome(e.target.value)} disabled={disabled} />
                        </Form.Group>
                        <Form.Group className='ml-5 mr-5'>
                            <Form.Label>Tipo organizzazione</Form.Label>
                            <Select value={tipoSelected}
                                options={tipoOrganizzazione}
                                id="input-tipo" aria-label="Tipo organizzazione" isDisabled={disabled}
                                onChange={e => setTipoSelected(e)} ></Select>
                        </Form.Group>
                        <Form.Group className='ml-5 mr-5 mb-5'>
                            <Form.Label>Dicitura da ricercare per 730 congiunto</Form.Label>
                            <Form.Control type="text" value={dicitura730Congiunto}
                                data-testid="input-dicitura" id="input-dicitura" aria-label="Dicitura 730 congiunto" onChange={e => setDicitura730Congiunto(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div>
                        {zone.map((zona, index) => {
                            return (
                                <div key={index} className='border'>
                                    <span
                                        aria-label={"rimuovi_zona_" + index}
                                        title="rimuovi zona"
                                        id={"rimuovi_zona_" + index}
                                        className='float-right btnRemove' onClick={() => removeZona(index)}>
                                        X
                                    </span>
                                    <Row className='m-3'>
                                        <Col sm={12} md={5}>
                                            <Form.Group className='ml-3'>
                                                {index === 0 ? <Form.Label>Codice zona</Form.Label> : ""}
                                                <Form.Control type="text" defaultValue={zona.codice}
                                                    data-testid="input-codice-zona" aria-label={"Codice zona-" + index} id={"input-codice-zona-" + index} name="codice"
                                                    onChange={event => handleZona(event, index)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={5}>
                                            <Form.Group className='ml-3'>
                                                {index === 0 ? <Form.Label>Nome zona</Form.Label> : ""}
                                                <Form.Control type="text" defaultValue={zona.descrizione}
                                                    data-testid="input-nome-zona" aria-label={"Nome zona-" + index} id={"input-nome-zona-" + index} name="descrizione"
                                                    onChange={event => handleZona(event, index)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={2}>
                                            <Form.Group className='ml-3'>
                                                {index === 0 ? <Form.Label>Esclusa</Form.Label> : ""}
                                                <Form.Check type="checkbox" defaultChecked={zona.esclusa}
                                                    className="mt-2 ml-3"
                                                    aria-label={"Esclusa-" + index} id={"input-esclusione-zona-" + index}
                                                    name="esclusa" onChange={event => handleZonaCheckbox(event, index)} />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col className='removeZone'>
                                            <Button id={"rimuovi_zona_" + index} className="btnGenerale" variant="flat" type="button" size="sm" onClick={() => removeZona(index)}>Rimuovi zona</Button>
                                        </Col> */}
                                    </Row>
                                </div>
                            )
                        })}
                        <div className='addZone'>
                            <Button id="aggiungi_zona" className="btnGenerale btnMinWidth btnaggiungizona" variant="flat" type="button"
                                active size="sm" onClick={() => addZona()}>Aggiungi zona</Button>
                        </div>

                    </div>

                    <div className='border  mt-3'>
                        <Form.Group className='ml-5 mr-5 mt-3 mb-5'>
                            <Form.Label>Applicativi</Form.Label>

                            <Select isMulti value={applicativi}
                                options={listaApplicativi}
                                onChange={updateSelections} aria-label="Applicativi" id="input-applicativi"></Select>
                        </Form.Group>
                    </div>
                    <div className='btnOrganizzazione'>
                        <Button className="btnGenerale btnMinWidth" variant="flat" id={idFunzione}
                            type="submit" size="sm">Conferma</Button>
                    </div>
                </Form>
            </Container>
        </div>
    )

}

export default AddEditOrganization