import {Button} from 'react-bootstrap';
import { buildUrl } from '../helper/handler';

export const getDatiFattura = (datifattura, format, type, nome, numero730inmodifica, nomeorganizzazione, tipoOrg, tutti) =>{
    const table = [];
    const errore = [];
    try {
        if(type === "riepilogo" || type === "riepilogoIsee") {
            getTabelleRiepiloghi(datifattura, table, nomeorganizzazione, tipoOrg, tutti, type);
        } else if(type === "riepilogototale") {
            getTabelleRiepilogoTotale(datifattura, table, nomeorganizzazione, tipoOrg, tutti)
        } else if(type === "produzionepersede") {
            getTabellaProduzioneerSede(datifattura, table, nomeorganizzazione, tipoOrg);
        } else {
            getTabelle730(datifattura, format, table, nome, numero730inmodifica, nomeorganizzazione, tipoOrg, tutti);
        }
    } catch (error) {  
        errore.push("Non è stato possibile visualizzare le tabelle, se il problema persiste contattare l'assistenza")
    }
    return {table, errore};
};

function getTabellaProduzioneerSede(datifattura, table, nomeorganizzazione, tipoOrg) {
    const tabellaProduzionePerSede = {};
    const intestazioneTabel = [];
    datifattura.intestazioni.forEach((item) => {
        intestazioneTabel.push(<th className="intestazioni th" key={item}>{item}</th>)
    })

    const rowsProduzionePerSede = [];
    datifattura.righe.forEach((datiZona) => {
        const row =[]
        row.push(<td className="thHorizontalSize" id={datiZona.zona} key={datiZona.zona}>{datiZona.zona}</td>);
        row.push(<td className="thHorizontalSize2 number" id={datiZona.zona + "_numero730"} 
            key={datiZona.zona + "_numero730"}>{datiZona.numero730}</td>);
        row.push(<td className="thHorizontalSize2 number" id={datiZona.zona + "_numero730AnnoPrecedente"} 
            key={datiZona.zona + "_numero730AnnoPrecedente"}>{datiZona.numero730AnnoPrecedente}</td>);
        row.push(<td className="thHorizontalSize2 number" id={datiZona.zona + "_numero730ConObiettivo"} 
            key={datiZona.zona + "_numero730ConObiettivo"}>{datiZona.numero730ConObiettivo}</td>);
        row.push(<td className="thHorizontalSize2 number" id={datiZona.zona + "_mancantiObiettivo730"} 
            key={datiZona.zona + "_mancantiObiettivo730"}>{datiZona.mancantiObiettivo730}</td>);
        row.push(<td className="thHorizontalSize2 number" id={datiZona.zona + "_appuntamentiFuturi"} 
            key={datiZona.zona + "_appuntamentiFuturi"}>{datiZona.appuntamentiFuturi}</td>);
        row.push(<td className="thHorizontalSize2 number" id={datiZona.zona + "_ipotesiProduzioneStampati"} 
            key={datiZona.zona + "_ipotesiProduzioneStampati"}>
            {datiZona.ipotesiProduzioneStampati}</td>);
        row.push(<td className="thHorizontalSize2 number" key={datiZona.zona + "_ipotesiProduzioneStampatiMancantiObiettivo"}
            id={datiZona.zona + "_ipotesiProduzioneStampatiMancantiObiettivo"}>
            {datiZona.ipotesiProduzioneStampatiMancantiObiettivo}</td>);
        row.push(<td className="thHorizontalSize2 number" key={datiZona.zona + "_appuntamentiInModifica"}
            id={datiZona.zona + "_appuntamentiInModifica"}>
            {datiZona.appuntamentiInModifica}</td>);
        row.push(<td className="thHorizontalSize2 number" key={datiZona.zona + "_ipotesiProduzioneStampatiEInModifica"}
            id={datiZona.zona + "_ipotesiProduzioneStampatiEInModifica"}>
            {datiZona.ipotesiProduzioneStampatiEInModifica}</td>);
        row.push(<td className="thHorizontalSize2 number" key={datiZona.zona + "_ipotesiProduz_ipotesiProduzioneStampatiFuturiEIniModificaMancantiObiettivoioneStampatiEIniModificaObiettivo"}
            id={datiZona.zona + "_ipotesiProduzioneStampatiFuturiEIniModificaMancantiObiettivo"}>
            {datiZona.ipotesiProduzioneStampatiFuturiEIniModificaMancantiObiettivo}</td>);
        row.push(<td className="thHorizontalSize2 number" key={datiZona.zona + "_ipotesiProduzioneStampatiFuturiEInModificaObiettivo"}
            id={datiZona.zona + "_ipotesiProduzioneStampatiFuturiEInModificaObiettivo"}>
            {datiZona.ipotesiProduzioneStampatiFuturiEInModificaObiettivo}</td>);
        rowsProduzionePerSede.push(row);
    })

    tabellaProduzionePerSede.intestazione = intestazioneTabel;
    tabellaProduzionePerSede.rows = rowsProduzionePerSede;
    tabellaProduzionePerSede.name = "Produzione per sede";
    const paramsObject = {
        tabella: 'PRODUZIONE_PER_SEDE',
        organizzazione: nomeorganizzazione,
        tipo: tipoOrg
    }
    const url = buildUrl('downloadcsv', paramsObject)
/*     const url = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=PRODUZIONE_PER_SEDE" +
    "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg; */
    tabellaProduzionePerSede.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm"
        href={url}>scarica</Button>
    </div>
    table.push(tabellaProduzionePerSede);
}

function getTabelleRiepilogoTotale(datifattura, table, nomeorganizzazione, tipoOrg, tutti) {
    Object.keys(datifattura).reverse().forEach((anno) => {
        const tabellaRiepilogoTotale = {};
        const intestazioneRiepilogoTotale = [];
        const rowsRiepilogoTotale = [];
        const element = datifattura[anno];
        intestazioneRiepilogoTotale.push(<th className="tdSize th" key={"Riepilogo730_"+anno}>{anno}</th>);
        const riepilogo730Intestazione = element.tabellaRiepilogoTotale.intestazioni;
        riepilogo730Intestazione.forEach((item) => {
            intestazioneRiepilogoTotale.push(<th className="intestazioni th" key={item+anno}>{item}</th>)
        })
        const riepilogo730Settimane = element.tabellaRiepilogoTotale.settimane;
        const settimanaCorrente = element.settimanacorrente
        Object.values(riepilogo730Settimane).forEach((dati) => {
            const row =[]
            const handlerTitle = anno + " settimana " + dati.numero
            const titileNumerocumulativo730 = handlerTitle +" numero cumulativo730"
            const titileVariazionenumerocumulativo730 = handlerTitle +" variazione numero cumulativo730"
            const titileNumeroSettimanale730 = handlerTitle +" numero settimanale730"
            const titileFatturatocumulativo730 = handlerTitle +" fatturato cumulativo730"
            const titileVariazioneFatturatoCumulativo730 = handlerTitle +" variazione fatturato cumulativo730"
            const titileFatturatoSettimanale730 = handlerTitle +" fatturato settimanale730"

            const titileAppuntamentiFissatiInSettimana = handlerTitle +" appuntamenti Fissati In Settimana"
            const titileIpotesiProduzioneTotale = handlerTitle +" ipotesi Produzione Totale"
            const titileIpotesiProduzioneTotaleSenzaStudi = handlerTitle +" ipotesi Produzione Totale Senza Studi"
            const titileInmodifica = handlerTitle +" in modifica"
            const titileTotali = handlerTitle +" totali"
            row.push(<td className="thHorizontalSize" key={anno+ "_"+dati.descrizione + "_settimana_" + dati.numero}>
                {dati.descrizione + " settimana " + dati.numero}</td>);
            row.push(<td className="tdSize number" key = {titileNumerocumulativo730} 
                title={titileNumerocumulativo730}>{dati.numerocumulativo730.toLocaleString('it-IT')}</td>);
            row.push(<td className="tdSize number" key={titileVariazionenumerocumulativo730} 
                title={titileVariazionenumerocumulativo730}>{dati.variazionenumerocumulativo730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ '%'}</td>);
            row.push(<td className="tdSize number" key={titileNumeroSettimanale730}
                title={titileNumeroSettimanale730}>{dati.numerosettimanale730.toLocaleString('it-IT')}</td>);
            row.push(<td className="tdSize number" key={titileFatturatocumulativo730}
                title={titileFatturatocumulativo730}>{dati.fatturatocumulativo730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>);
            row.push(<td className="tdSize number" key={titileVariazioneFatturatoCumulativo730}
                title={titileVariazioneFatturatoCumulativo730}>{dati.variazionefatturatocumulativo730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ '%'}</td>);
            row.push(
                <td className="tdSize number" 
                    key={titileFatturatoSettimanale730}
                    title={titileFatturatoSettimanale730} >
                    {dati.fatturatosettimanale730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>);
            row.push(
                <td className="tdSize number" 
                    key={titileAppuntamentiFissatiInSettimana}
                    title={titileAppuntamentiFissatiInSettimana} >
                    {dati.appuntamentiFissatiInSettimana.toLocaleString('it-IT')}
                </td>);
            row.push(
                <td className="tdSize number" 
                    key={titileIpotesiProduzioneTotale}
                    title={titileIpotesiProduzioneTotale} >
                    {dati.ipotesiProduzioneTotale.toLocaleString('it-IT')}
                </td>);
            row.push(
                <td className="tdSize number" 
                    key={titileIpotesiProduzioneTotaleSenzaStudi}
                    title={titileIpotesiProduzioneTotaleSenzaStudi} >
                    {dati.ipotesiProduzioneTotaleSenzaStudi.toLocaleString('it-IT')}
                </td>);
            if (dati.numero === settimanaCorrente) {
                row.push(
                    <td className="tdSize number" 
                        key={titileInmodifica}
                        title={titileInmodifica} >
                        {dati.inmodifica.toLocaleString('it-IT')}
                    </td>);
                row.push(
                    <td className="tdSize number" 
                        key={titileTotali}
                        title={titileTotali} >
                        {dati.totali.toLocaleString('it-IT')}
                    </td>);
            } else {
                row.push(
                    <td className="tdSize number" 
                        key={titileInmodifica}
                        title={titileInmodifica} >0</td>);
                row.push(
                    <td className="tdSize number" 
                        key={titileTotali}
                        title={titileTotali} >0</td>);
            }

            rowsRiepilogoTotale.push(row);
        })
       
        tabellaRiepilogoTotale.intestazione = intestazioneRiepilogoTotale;
        tabellaRiepilogoTotale.rows = rowsRiepilogoTotale;
        tabellaRiepilogoTotale.name = anno + " Riepilogo totale"

        const paramsObject = {
            tabella: 'RIEPILOGO_TOTALE',
            anno: anno,
            organizzazione: nomeorganizzazione,
            tipo: tipoOrg,
            tutti: tutti
        }
        const url = buildUrl('downloadcsv', paramsObject)
        /* const url = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=RIEPILOGO_TOTALE&anno=" + anno +
        "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg +  "&tutti=" + tutti; */
        tabellaRiepilogoTotale.button = <div id="csv" className="downloadcsv">
            <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm"
            href={url} data-testid={"download_riepilogo_totale" + anno}>scarica</Button>
        </div>
        table.push(tabellaRiepilogoTotale);

    })
}

function getTabelleRiepiloghi(datifattura, table, nomeorganizzazione, tipoOrg, tutti, type) {
    Object.keys(datifattura).reverse().forEach((anno) => {
        const element = datifattura[anno];
        var downloadUrlAppuntamenti = ""
        var downloadUrlIpotesiProduzione = ""
        if (type === "riepilogo") {
            getTabellaRiepilogativo730(anno, element, nomeorganizzazione, tipoOrg, tutti, table);
            downloadUrlAppuntamenti = "RIEPILOGATIVO_APPUNTAMENTI";
            downloadUrlIpotesiProduzione = "IPOTESI_PRODUZIONE_SETTIMANALE_730"
        } else {
            getTabellaRiepilogativoIsee(anno, element, nomeorganizzazione, tipoOrg, tutti, table);
            downloadUrlAppuntamenti = "RIEPILOGATIVO_APPUNTAMENTI_ISEE";
            downloadUrlIpotesiProduzione = "IPOTESI_PRODUZIONE_SETTIMANALE_ISEE"
        }

        const tabellaAppuntamenti = {};
        const intestazioneAppuntamenti = [];
        const rowsAppuntamenti = [];

        intestazioneAppuntamenti.push(<th className="tdSize th" key={"Appuntamenti_"+anno}>{anno}</th>);
        const riepilogoappuntamentiIntestazione = element.riepilogoappuntamenti.intestazioni;
        riepilogoappuntamentiIntestazione.forEach((item) => {
            intestazioneAppuntamenti.push(<th className="intestazioni th" key={item+anno}>{item}</th>)
        })
        const riepilogoappuntamentiSettimane = element.riepilogoappuntamenti.settimane;
        // const listAltri = []
        // Object.values(riepilogoappuntamentiSettimane).forEach((dati) => {
        //     Object.keys(dati.altriapplicativi).forEach((altri) => {
        //         if (!listAltri.includes(altri)) {
        //             listAltri.push(altri)
        //             intestazioneAppuntamenti.push(<th className="intestazioni th" key={"intestazione_"+ altri+"_"+anno}>appuntamenti {altri}</th>)
        //             intestazioneAppuntamenti.push(<th className="intestazioni th" key={"intestazione_"+ altri+ "_fissati_in_settimana_"+anno}>appuntamenti fissati in settimana {altri}</th>)
        //         }
        //     })
        // })
        Object.values(riepilogoappuntamentiSettimane).forEach((dati) => {
            const row =[]
            const handlerTitle = anno + " settimana " + dati.numero
            const titileAppuntamentitotali = handlerTitle +" appuntamen titotali"
            const titileAppuntamentifuturi = handlerTitle +" appuntamenti futuri"
            const titileAppuntamentiFissatiInSettimana = handlerTitle +" appuntamenti fissati in settimana"
            row.push(<td className="thHorizontalSize" key={anno + "_"+ dati.descrizione + "_settimana " + dati.numero}
                id={anno + "_"+ dati.descrizione + "_settimana " + dati.numero}>
                {dati.descrizione + " settimana " + dati.numero}</td>);
            row.push(<td className="tdSize number" title={titileAppuntamentitotali} 
                key={titileAppuntamentitotali} id={titileAppuntamentitotali}>
                {dati.appuntamentiTotali.toLocaleString('it-IT')}</td>);
            row.push(<td className="tdSize number" title={titileAppuntamentifuturi}
                key={titileAppuntamentifuturi} id={titileAppuntamentifuturi}>
                {dati.appuntamentiFuturi.toLocaleString('it-IT')}</td>);
            row.push(<td className="tdSize number" title={titileAppuntamentiFissatiInSettimana}
                key={titileAppuntamentiFissatiInSettimana} id={titileAppuntamentiFissatiInSettimana}>
                {dati.appuntamentiFissatiInSettimana.toLocaleString('it-IT')}</td>);
            
            Object.keys(dati.altriapplicativi).forEach((altri) => {
                const titileAltriAppuntamenti = handlerTitle +" appuntamenti " + altri
                const titileAltriAppuntamentiFissatiInSettimana = 
                handlerTitle +" appuntamenti fissati in settimana " + altri 
                row.push(<td className="tdSize number" 
                    key={titileAltriAppuntamenti} title={titileAltriAppuntamenti} id={titileAltriAppuntamenti}> 
                    {dati.altriapplicativi[altri].totali.toLocaleString('it-IT')}</td>);
                
                row.push(<td className="tdSize number" title={titileAltriAppuntamentiFissatiInSettimana}
                    key={titileAltriAppuntamentiFissatiInSettimana}  id={titileAltriAppuntamentiFissatiInSettimana}>
                    {dati.altriapplicativi[altri].fissatiInSettimana.toLocaleString('it-IT')}</td>);
            })
            rowsAppuntamenti.push(row);
        })
        tabellaAppuntamenti.intestazione = intestazioneAppuntamenti;
        tabellaAppuntamenti.rows = rowsAppuntamenti;
        tabellaAppuntamenti.name = anno + " Riepilogativo Appuntamenti"

        const paramsObject = {
            tabella: downloadUrlAppuntamenti,
            anno: anno,
            organizzazione: nomeorganizzazione,
            tipo: tipoOrg,
            tutti: tutti
        }
        const url_appuntamenti = buildUrl('downloadcsv', paramsObject)
       /*  const url_appuntamenti = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=" + 
            downloadUrlAppuntamenti + "&anno=" + anno +
        "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg +  "&tutti=" + tutti; */
        tabellaAppuntamenti.button = <div id="csv" className="downloadcsv">
            <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm"
            href={url_appuntamenti} data-testid={"download_riepilogativo_appuntamenti" + anno}>scarica</Button>
        </div>
        table.push(tabellaAppuntamenti);

        const tabellaIpotesi = {};
        const intestazioneIpotesi = [];
        const rowsIpotesi = [];
        const riepilogoIpotesiIntestazione = element.riepilogoipotesiproduzione.intestazioni;
        intestazioneIpotesi.push(<th className="intestazioni th" key={"iptesi"+anno}></th>)
        riepilogoIpotesiIntestazione.forEach((item) => {
            intestazioneIpotesi.push(<th className="intestazioni th" key={item+anno}>{item}</th>)
        })
        const riepilogoIpotesiSettimane = element.riepilogoipotesiproduzione.settimane;
        Object.values(riepilogoIpotesiSettimane).forEach((dati) => {
            const row =[]
            const handlerTitle = anno + " settimana " + dati.numero
            const titileIpotesiProduzioneTotaleAl30settembre = handlerTitle +" ipotesi produzione totale al 30 settembre"
            const titileIpotesiProduzioneSenzaStudi = handlerTitle +" ipotesi produzione senza studi al 30 settembre"
            row.push(<td className="thHorizontalSize" key={anno+ "_"+dati.descrizione + "_settimana_" + dati.numero}
                id={anno+ "_"+dati.descrizione + "_settimana_" + dati.numero}>
                {dati.descrizione + " settimana " + dati.numero}</td>);
            row.push(<td className="tdSize number" title={titileIpotesiProduzioneTotaleAl30settembre}
                key={titileIpotesiProduzioneTotaleAl30settembre}  id={titileIpotesiProduzioneTotaleAl30settembre}>
                {dati.ipotesiProduzioneTotale.toLocaleString('it-IT')}</td>);
            row.push(<td className="tdSize number" title={titileIpotesiProduzioneSenzaStudi}
                key={titileIpotesiProduzioneSenzaStudi}  id={titileIpotesiProduzioneSenzaStudi}>
                {dati.ipotesiProduzioneTotaleSenzaStudi.toLocaleString('it-IT')}</td>);
            rowsIpotesi.push(row);
        })
        tabellaIpotesi.intestazione = intestazioneIpotesi;
        tabellaIpotesi.rows = rowsIpotesi;
        tabellaIpotesi.name = anno + " Riepilogativo Ipotesi Produzione"
        const paramsObject_ipotesi = {
            tabella: downloadUrlIpotesiProduzione,
            anno: anno,
            organizzazione: nomeorganizzazione,
            tipo: tipoOrg,
            tutti: tutti
        }
        const url_ipotesi = buildUrl('downloadcsv', paramsObject_ipotesi)
        /* const url_ipotesi = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=" + 
        downloadUrlIpotesiProduzione + "&anno=" + anno +
        "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg +  "&tutti=" + tutti; */
        tabellaIpotesi.button = <div id="csv" className="downloadcsv">
            <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm"
            href={url_ipotesi} data-testid={"download_riepilogativo_ipotesi_produzione" + anno}>scarica</Button>
        </div>
        table.push(tabellaIpotesi);
    })
}

function getTabellaRiepilogativo730(anno, element, nomeorganizzazione, tipoOrg, tutti, table) {
    const tabellaRiepilogo730 = {};
    const intestazioneRiepilogo730 = [];
    const rowsRiepilogo730 = [];
    intestazioneRiepilogo730.push(<th className="tdSize th" key={"Riepilogo730_" + anno}>{anno}</th>);
    const riepilogo730Intestazione = element.riepilogo730.intestazioni;
    riepilogo730Intestazione.forEach((item) => {
        intestazioneRiepilogo730.push(<th className="intestazioni th" key={item + anno}>{item}</th>);
    });
    const riepilogo730Settimane = element.riepilogo730.settimane;
    Object.values(riepilogo730Settimane).forEach((dati) => {
        const row = [];
        const handlerTitle = anno + " settimana " + dati.numero;
        const titileNumerocumulativo730 = handlerTitle + " numero cumulativo730";
        const titileVariazionenumerocumulativo730 = handlerTitle + " variazione numero cumulativo730";
        const titileNumeroSettimanale730 = handlerTitle + " numero settimanale730";
        const titileFatturatocumulativo730 = handlerTitle + " fatturato cumulativo730";
        const titileVariazioneFatturatoCumulativo730 = handlerTitle + " variazione fatturato cumulativo730";
        const titileFatturatoSettimanale730 = handlerTitle + " fatturato settimanale730";
        row.push(<td className="thHorizontalSize" key={anno + "_" + dati.descrizione + "_settimana_" + dati.numero}
            id={anno + "_" + dati.descrizione + "_settimana_" + dati.numero}>
            {dati.descrizione + " settimana " + dati.numero}</td>);
        row.push(<td className="tdSize number" key={titileNumerocumulativo730} id={titileNumerocumulativo730}
            title={titileNumerocumulativo730}>{dati.numerocumulativo730.toLocaleString('it-IT')}</td>);
        row.push(<td className="tdSize number" key={titileVariazionenumerocumulativo730} id={titileVariazionenumerocumulativo730}
            title={titileVariazionenumerocumulativo730}>{dati.variazionenumerocumulativo730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}</td>);
        row.push(<td className="tdSize number" key={titileNumeroSettimanale730} id={titileNumeroSettimanale730}
            title={titileNumeroSettimanale730}>{dati.numerosettimanale730.toLocaleString('it-IT')}</td>);
        row.push(<td className="tdSize number" key={titileFatturatocumulativo730} id={titileFatturatocumulativo730}
            title={titileFatturatocumulativo730}>{dati.fatturatocumulativo730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>);
        row.push(<td className="tdSize number" key={titileVariazioneFatturatoCumulativo730} id={titileVariazioneFatturatoCumulativo730}
            title={titileVariazioneFatturatoCumulativo730}>{dati.variazionefatturatocumulativo730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}</td>);
        row.push(<td className="tdSize number" key={titileFatturatoSettimanale730} id={titileFatturatoSettimanale730}
            title={titileFatturatoSettimanale730}>{dati.fatturatosettimanale730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>);
        rowsRiepilogo730.push(row);
    });
    const numeri730Stampati = element.numero730.stampati;
    const numeri730InModifica = element.numero730.inmodifica;
    const numeri730Totali = element.numero730.totali;
    tabellaRiepilogo730.intestazione = intestazioneRiepilogo730;
    tabellaRiepilogo730.rows = rowsRiepilogo730;
    tabellaRiepilogo730.name = anno + " Riepilogativo 730";
    const numeri730 = {};
    numeri730.stampati = numeri730Stampati;
    numeri730.inmodifica = numeri730InModifica;
    numeri730.totali = numeri730Totali;
    tabellaRiepilogo730.numeri730 = numeri730;
    const paramsObject_730 = {
        tabella: 'RIEPILOGATIVO_730',
        anno: anno,
        organizzazione: nomeorganizzazione,
        tipo: tipoOrg,
        tutti: tutti
    }
    const url730 = buildUrl('downloadcsv', paramsObject_730)
    /* const url730 = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=RIEPILOGATIVO_730&anno=" + anno +
        "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg + "&tutti=" + tutti; */
    tabellaRiepilogo730.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm"
            href={url730} data-testid={"download_riepilogativo730" + anno}>scarica</Button>
    </div>;
    table.push(tabellaRiepilogo730);
}

function getTabellaRiepilogativoIsee(anno, element, nomeorganizzazione, tipoOrg, tutti, table) {

    const tabellaRiepilogoIsee = {};
    const intestazioneRiepilogoIsee = [];
    const rowsRiepilogoIsee = [];
    intestazioneRiepilogoIsee.push(<th className="tdSize th" key={"RiepilogoIsee_" + anno}>{anno}</th>);
    const riepilogoIseeIntestazione = element.riepilogoisee.intestazioni;
    riepilogoIseeIntestazione.forEach((item) => {
        intestazioneRiepilogoIsee.push(<th className="intestazioni th" key={item + anno}>{item}</th>);
    });
    const riepilogoIseeSettimane = element.riepilogoisee.settimane;
    Object.values(riepilogoIseeSettimane).forEach((dati) => {
        const row = [];
        const handlerTitle = anno + " settimana " + dati.numero;
        const titileNumerocumulativoIsee = handlerTitle + " numero cumulativo Isee";
        const titileVariazionenumerocumulativoIsee = handlerTitle + " variazione numero cumulativo Isee";
        const titileNumeroSettimanaleIsee = handlerTitle + " numero settimanale Isee";
        row.push(<td className="thHorizontalSize" key={anno + "_" + dati.descrizione + "_settimana_" + dati.numero}
            id={anno + "_" + dati.descrizione + "_settimana_" + dati.numero}>
            {dati.descrizione + " settimana " + dati.numero}</td>);
        row.push(<td className="tdSize number" key={titileNumerocumulativoIsee} id={titileNumerocumulativoIsee}
            title={titileNumerocumulativoIsee}>{dati.numerocumulativoisee.toLocaleString('it-IT')}</td>);
        row.push(<td className="tdSize number" key={titileVariazionenumerocumulativoIsee}
            id={titileVariazionenumerocumulativoIsee}
            title={titileVariazionenumerocumulativoIsee}>{dati.variazionenumerocumulativoisee.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}</td>);
        row.push(<td className="tdSize number" key={titileNumeroSettimanaleIsee} id={titileNumeroSettimanaleIsee}
            title={titileNumeroSettimanaleIsee}>{dati.numerosettimanaleisee.toLocaleString('it-IT')}</td>);
        rowsRiepilogoIsee.push(row);
    });
    const numeriIseeStampati = element.numeroisee.stampati;
    const numeriIseeInModifica = element.numeroisee.inmodifica;
    const numeriIseeTotali = element.numeroisee.totali;
    tabellaRiepilogoIsee.intestazione = intestazioneRiepilogoIsee;
    tabellaRiepilogoIsee.rows = rowsRiepilogoIsee;
    tabellaRiepilogoIsee.name = anno + " Riepilogativo ISEE";
    const numeriIsee = {};
    numeriIsee.stampati = numeriIseeStampati;
    numeriIsee.inmodifica = numeriIseeInModifica;
    numeriIsee.totali = numeriIseeTotali;
    tabellaRiepilogoIsee.numeriIsee = numeriIsee;
    const paramsObject_Isee = {
        tabella: 'RIEPILOGATIVO_ISEE',
        anno: anno,
        organizzazione: nomeorganizzazione,
        tipo: tipoOrg,
        tutti: tutti
    }
    const urlIsee = buildUrl('downloadcsv', paramsObject_Isee)
    /* const urlIsee = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=RIEPILOGATIVO_ISEE&anno=" + anno +
        "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg + "&tutti=" + tutti; */
    tabellaRiepilogoIsee.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm"
            href={urlIsee} data-id={"download_riepilogativoisee" + anno}>scarica</Button>
    </div>;
    table.push(tabellaRiepilogoIsee);
}

function getTabelle730(datifattura, format, table, nome, numero730inmodifica, nomeorganizzazione, tipoOrg, tutti) {
    Object.keys(datifattura).reverse().forEach((anno) => {
        var tabellaAnnuo = {};
        const rows = [];
        const intestazione = [];
        var element = datifattura[anno];
        intestazione.push(<th rowSpan="2" className="fixboth" key="centri">Centri</th>);
        var total = [];
        var totaleCumulativi = []
        total.push(<td className="thHorizontalSize fix" key="totale"><b>{"totale"}</b></td>);
        var settimanaCorrente = element.settimanacorrente
        if(numero730inmodifica) {
            totaleCumulativi.push(<td className="thHorizontalSize fix" key={"totale_cumulativo_" +anno}
                ><b>{"totale cumulativo"}</b></td>);
            Object.keys(element.totali_cumulativi).forEach((totale, index) => {
                var value = element.totali_cumulativi[totale].valore.toLocaleString('it-IT');
                var indice = parseInt(index) + 1;
                var stile = "tdSize number"
                if(indice === settimanaCorrente) {
                    stile = "tdSize number evidenza"
                }
                var titleTotale = anno + " settimana " + indice;
                totaleCumulativi.push(<td colSpan="2" className={stile} title={titleTotale}
                    key={"totali_cumulativo_"+anno+"_"+index}>{value}</td>);
                
            });
            rows.push(totaleCumulativi);
        }
        Object.keys(element.totali).forEach((totale, index) => {
            var value = element.totali[totale].valore.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            if (format) {
                value = element.totali[totale].valore.toLocaleString('it-IT');
            }
            var indice = parseInt(index) + 1;
            var stile = "tdSize number"
            if(indice === settimanaCorrente) {
                stile = "tdSize number evidenza"
            }
            var titleTotale = anno + " settimana " + indice;
            var formatPercentual = element.totali[totale].variazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            if (numero730inmodifica){
                total.push(<td colSpan="2" className={stile} title={titleTotale} id={titleTotale}
                key={"totali_inmodifica_"+anno+"_"+index}>{value}</td>);
            } else {
                total.push(<td className={stile} id={titleTotale} title={titleTotale} key={"totale_"+anno+"_"+index}>{value}</td>);
                total.push(<td className={stile} id={titleTotale} title={titleTotale} key={"totale_variazione_"+anno+"_"+index}>{formatPercentual + '%'}</td>);
            }
            
        });
        rows.push(total);
        Object.keys(element.zone).forEach(zona => {
            var row = [];
            row.push(<td className="thHorizontalSize fix" key={anno + "_" + zona}>{zona}</td>);
            Object.values(element.zone[zona]).forEach((valori, index) => {
                var value = valori.valore.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                if (format) {
                    value = valori.valore.toLocaleString('it-IT');
                }
                var indice = parseInt(index) + 1;
                var stile = "tdSize number"
                if(indice === settimanaCorrente) {
                    stile = "tdSize number evidenza"
                }
                var titleValore = anno + " settimana " + indice + " - " + zona;
                var formatPercentual = valori.variazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                if (numero730inmodifica){
                    row.push(<td colSpan="2" className={stile} title={titleValore} id={titleValore}
                            key={"inmodifica_"+anno+"_"+index}>{value}</td>);
                } else {
                    row.push(<td className={stile} title={titleValore} id={titleValore}
                        key={"valore_settimanale_"+anno+"_"+index}>{value}</td>);
                    row.push(<td className={stile} title={titleValore} id={titleValore + ' percentuale'}
                        key={"percentuale_settimanale_"+anno+"_"+index}>{formatPercentual + '%'}</td>);
                }
            });
            rows.push(row);
        });
        rows.push(total);
        if (numero730inmodifica) {
            rows.push(totaleCumulativi);
        }
        Object.values(element.intestazioni).forEach(element => {
            intestazione.push(<th rowSpan="2" colSpan="2" key={element.descrizione} className="intestazioni th thMinSize">
                {"settimana " + element.numero + " "}
                <br />{element.descrizione}</th>);
        });
        tabellaAnnuo.intestazione = intestazione;
        tabellaAnnuo.rows = rows;
        tabellaAnnuo.name = anno;
        const paramsObject = {
            tabella: nome.toLowerCase(),
            anno: anno,
            organizzazione: nomeorganizzazione,
            tipo: tipoOrg,
            tutti: tutti
        }
        const url = buildUrl('downloadcsv', paramsObject)
        /* const url = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=" + nome.toLowerCase()+ "&anno=" + anno 
        + "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg +  "&tutti=" + tutti; */
        tabellaAnnuo.button = <div id="csv" className="mb-1">
            <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size='sm'
            href={url} data-testid={"download_" + nome}>scarica</Button>
        </div>
        table.push(tabellaAnnuo);
    });
}
