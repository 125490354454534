import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation, useHistory, Link } from 'react-router-dom';
import './css/App.css';
import { useState, useEffect } from 'react'
import Logo from './logoaclitrasparentebianco.png'
import { getUserFromStorage, removeUserFromStorage } from './helper/handler';
import { getFromApi } from './utilities/NetUtilities';
import { buildUrl } from './helper/handler';

const Header = () => {

  const [userLogged, setUserLogged] = useState(null);
  const [showOrganizzazioni, setShowOrganizzazioni] = useState(false);
  const [showUtenti, setShowUtenti] = useState(false);
  const [showOrarioLavoro, setShowOrarioLavoro] = useState(false);
  const [showObiettivi, setShowObiettivi] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const location = useLocation()
  const [headerCase, setHeaderCase] = useState("navbarDefault")
  const [orgLogged, setOrgLogged] = useState({ nome: "", tipo: "" })
  const history = useHistory();

  useEffect(() => {
    var organizationLogged = window.sessionStorage.getItem("organizzazione")
    if (organizationLogged) {
      setOrgLogged(JSON.parse(organizationLogged))
    }
    const getShow = (user) => {
      setUserLogged(user);
      if (user.role === 'AMMINISTRATORE' || user.role === 'SUPER_AMMINISTRATORE') {
        setShowUtenti(true);
        setShowOrarioLavoro(true);
        setShowOrganizzazioni(true);
        setShowObiettivi(true)
      }
      if (user.role === 'AMMINISTRATORE' || user.role === 'SUPER_AMMINISTRATORE' || user.role === 'AMMINISTRATORE_ORGANIZZAZIONE') {
        setShowArea(true)
      }
      const pathName = location.pathname
      const list = ["/", "/login", "/logout", "/landingpage",
        "/gestioneutenti", "/gestioneorganizzazioni", "/addorganizzazione", "/editorganizzazione",
        "/orariolavorolavoratore", "/orariolavorosede", "/orariolavorosettimana", "/reportorariolavoro"]
      if (!list.includes(pathName) || organizationLogged) {
        setHeaderCase("navbarAnalisi")
      }
      if (pathName === "/editorganizzazione" && organizationLogged) {
        setHeaderCase("navbarAnalisi")
      }
      if (pathName === "/resetpassword") {
        setHeaderCase("navbarEmpty")
      }
      if (pathName === "/landingpage" ) {
        setHeaderCase("navbarDefault")
      }
    }
    const user = getUserFromStorage();
    if (user) {
      getShow(user);
    }
  }, [location])

  const logOut = () => {
    removeUserFromStorage();
    window.sessionStorage.removeItem("username");
    window.sessionStorage.removeItem("password");
    const url = buildUrl("logout");
    getFromApi(url, null, null);
    history.push("/");
  }

  const switchHeader = () => {
    switch (headerCase) {
      case "navbarDefault":
        return (
          <div className="header">
            <Navbar expand="sm" data-testid="navbar">
              <Navbar.Brand to="/landingpage">
                <img src={Logo} height="80" alt="logo acli" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu" />
              {userLogged && <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`ml-auto`} variant="pills" >
                  <NavDropdown title={userLogged.username} id="logged" className='dropdown-menu-left'>
                    <NavDropdown.Item id="home" as={Link} to="landingpage">Home</NavDropdown.Item>
                    {/* {showOrarioLavoro &&
                      <NavDropdown title="Gestione turni lavoro" id="menu_orariolavoro"
                        drop={"left"} className='submenu'>
                        <NavDropdown.Item as={Link} id="orariolavorolavoratore" to="orariolavorolavoratore">turni lavoro</NavDropdown.Item>
                        <NavDropdown.Item id="reportorario" as={Link} to="/reportorariolavoro">report</NavDropdown.Item>
                      </NavDropdown>} */}
                    {showOrganizzazioni &&
                      <NavDropdown.Item id="gestione_organizzazioni" as={Link} to="/gestioneorganizzazioni">
                        Gestione organizzazioni
                      </NavDropdown.Item >}
                    {showUtenti &&
                      <NavDropdown.Item id="gestione_utenti" as={Link} to="/gestioneutenti">Gestione utenti
                      </NavDropdown.Item >}
                    <NavDropdown.Item id="logout" data-testid="logout"
                      onClick={logOut}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>}
            </Navbar>
          </div>
        )
      case "navbarAnalisi":
        return (
          <div className="header">
            <Navbar expand="lg" data-testid="navbar">
              <Navbar.Brand href="/landingpage" id="logo">
                <img src={Logo} height="80" alt="logo acli" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu" />
              {userLogged && <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`ml-auto`} variant="pills" >
                  <NavDropdown title={userLogged.username} id="logged" className='dropdown-menu-left'>
                    <NavDropdown.Item id="home" href="/landingpage" >Home</NavDropdown.Item>
                    {showOrarioLavoro &&
                      <NavDropdown title="Gestione turni lavoro" id="menu_orariolavoro"
                      drop={"left"} className='submenu'>
                      <NavDropdown.Item as={Link} id="orariolavorolavoratore" to="orariolavorolavoratore">turni lavoro</NavDropdown.Item>
                      <NavDropdown.Item id="reportorario" as={Link} to="/reportorariolavoro">report</NavDropdown.Item>
                      <NavDropdown.Item id="gestionelavoratori" as={Link} to="/gestionelavoratori">gestione lavoratori</NavDropdown.Item>
                      <NavDropdown.Item id="gestionesedi" as={Link} to="/gestionesedi">gestione sedi</NavDropdown.Item>
                      <NavDropdown.Item id="gestioneservizi" as={Link} to="/gestioneservizi">gestione servizi</NavDropdown.Item>
                    </NavDropdown>}
                    <NavDropdown.Item id="riepilogo_generale" as={Link}
                      to="/riepilogogenerale">Riepilogo generale</NavDropdown.Item>
                    <NavDropdown.Item id="importa_dati" to="/caricafile" as={Link}>
                      Importa dati
                    </NavDropdown.Item>
                    {showObiettivi &&
                      <NavDropdown.Item id="obiettivo" to="/obiettivo" as={Link} >
                        Obiettivi
                      </NavDropdown.Item>}

                    <NavDropdown title="Aree" drop={"left"} className='submenu' id="aree">
                      <NavDropdown.Item id="area730" to="/area730" as={Link}>730</NavDropdown.Item>
                      <NavDropdown.Item id="areaImu" to="/areaimu" as={Link}>Imu</NavDropdown.Item>
                      <NavDropdown.Item id="areaUnico" to="/areaunico" as={Link}>Unico</NavDropdown.Item>
                      <NavDropdown.Item id="areaCondomini" to="/areacondomini" as={Link}>Condomini</NavDropdown.Item>
                      <NavDropdown.Item id="areaSuccessioni" to="/areasuccessioni" as={Link}>Successioni</NavDropdown.Item>
                      <NavDropdown.Item id="areaLocazioni" to="/arealocazioni" as={Link}>Locazioni</NavDropdown.Item>
                      <NavDropdown.Item id="areaVarie" to="/areavarie" as={Link}>Varie</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Campagna 730" drop={"left"} className='submenu' id="campagna730">
                      <NavDropdown.Item id="fatturatoSettimanale730" to="/fatturatosettimanale730" as={Link}>
                        Fatturato settimanale 730
                      </NavDropdown.Item>
                      <NavDropdown.Item id="fatturatoCumulativo730" to="/fatturatocumulativo730" as={Link}>
                        Fatturato cumulativo 730
                      </NavDropdown.Item>
                      <NavDropdown.Item id="numeroSettimanale730" to="/numerosettimanale730" as={Link}>
                        Numero settimanale 730
                      </NavDropdown.Item>
                      <NavDropdown.Item id="numeroCumulativo730" to="/numerocumulativo730" as={Link}>
                        Numero cumulativo 730
                      </NavDropdown.Item>
                      <NavDropdown.Item id="numeroSettimanale730InModifica" to="/numerosettimanale730inmodifica" as={Link}>
                        Numero settimanale 730 in modifica
                      </NavDropdown.Item>
                      <NavDropdown.Item id="riepiloghi" to="/riepiloghi" as={Link}>
                        Riepiloghi
                      </NavDropdown.Item>
                      <NavDropdown.Item id="riepilogoTotale" to="/riepilogototale" as={Link}>
                        Riepilogo totale
                      </NavDropdown.Item>
                      <NavDropdown.Item id="produzionePerSede" to="/produzionepersede" as={Link}>
                        Produzione per sede
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Campagna Isee" drop={"left"} className='submenu' id="campagnaIsee">
                      <NavDropdown.Item id="numeroSettimanaleIsee" to="/numerosettimanaleisee" as={Link}>
                        Numero settimanale ISEE
                      </NavDropdown.Item>
                      <NavDropdown.Item id="numeroCumulativoIsee" to="/numerocumulativoisee" as={Link}>
                        Numero cumulativo ISEE
                      </NavDropdown.Item>
                      <NavDropdown.Item id="numeroSettimanaleIseeInModifica" to="/numerosettimanaleiseeinmodifica" as={Link}>
                        Numero settimanale ISEE in modifica
                      </NavDropdown.Item>
                      <NavDropdown.Item id="riepiloghiIsee" to="/riepiloghiisee" as={Link}>
                        Riepiloghi ISEE
                      </NavDropdown.Item>
                    </NavDropdown>

                    {showArea &&
                      <NavDropdown title="Gestione organizzazione" id="menu_organizzazione"
                        drop={"left"} className='submenu'>
                        <NavDropdown.Item as={Link} id="zone_applicativi"
                          to={{ pathname: `/editorganizzazione`, search: `nome=${orgLogged.nome}&tipo=${orgLogged.tipo}` }}>
                          Zone / Applicativi</NavDropdown.Item>
                        <NavDropdown.Item id="associazione_area" as={Link}
                          to="/associazionearea">Associazione area
                        </NavDropdown.Item>
                      </NavDropdown>
                    }
                    {showUtenti &&
                      <NavDropdown.Item id="gestione_utenti" as={Link} to="/gestioneutenti">Gestione utenti
                      </NavDropdown.Item >}
                    <NavDropdown.Item id="logout" data-testid="logout" as={Link} to="#"
                      onClick={logOut}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>}
            </Navbar>
          </div>
        )
      case "navbarEmpty":
        return (
          <div className="header">
            <Navbar expand="lg" data-testid="navbar">
              <Navbar.Brand href="/" id="logo">
                <img src={Logo} height="80" alt="logo acli" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu" />
            </Navbar>
          </div>
        )
      default:
        break
    }
  }

  return (
    <>
      {switchHeader()}
    </>
  )

}

export default Header;