import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { TurnoLavoratore } from "./TurnoLavoratore";
import { RipetiGiorno } from "./RipetiGiorno";
import { FaPlus } from 'react-icons/fa';
import { useRef, useEffect, useState } from 'react';
import { getSede } from './turniHelper';

export function TurniGiorno(props) {
    const [indexAggiungi, setIndexAggiungi] = useState('')
    const [indexTurno, setIndexTurno] = useState('')

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    useEffect(() => {
        setIndexAggiungi(props.index);
        setIndexTurno(props.index + "turno");
    }, [props.index]);

    const addTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, {
                descrizione: value.messaggio,
                tipo: value.tipo.toLowerCase()
            }])
        ))
        for (var i = 0; i < props.turni.length; i++) {
            if (props.turni[i].id === data.turno.id) {
                props.turni.splice(i, 1);
            }
        }
        props.turni.push(data.turno);
        props.turni.sort(function (a, b) {
            return a.inizio > b.inizio
        })
        closeTooltip();
    }

    const elimnaTurno = (data) => {
        props.setMessage([]);
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message,
            { descrizione: value.descrizione, tipo: value.tipo }])
        ))
        for (var i = 0; i < props.turni.length; i++) {
            if (props.turni[i].id === data.id) {
                props.turni.splice(i, 1);
            }
        }
        closeTooltip();
    }

    const formatPostazione = (sedeId, postazione) => {
        const sede = getSede(props.sedi, sedeId);
        var postazioni = sede.postazioni
        var postazioneDesc = "";
        if (postazioni) {
            postazioneDesc = postazioni[postazione];
        }
        return sede ? sede.descrizione + "/" + postazioneDesc : "sconosciuta";
    }

    const formatServizio = (servizio) => {
        return props.servizi[servizio][0];
    }

    const formatTurno = (turno) => {
        return turno.inizio + "-"
            + turno.fine + " "
            + formatPostazione(turno.sedeId, turno.postazione) + ": "
            + formatServizio(turno.servizioId)
    }

    const getClassForSede = (sedeId) => {
        const sede = props.sedi.find(sede => sede.id === sedeId);
        return sedeId ? { backgroundColor: sede.colore } : {};
    };

    const getPopup = (trigger, turno, cssClass, index) => {
        return <Popup
            ref={ref}
            trigger={<button className={`${cssClass}`} style={getClassForSede(turno.sedeId)} id={index}>{trigger}</button>}
            position="bottom center"
            modal>{
                close =>
                    <TurnoLavoratore
                        id={turno.id}
                        sede={turno.sedeId}
                        inizio={turno.inizio}
                        fine={turno.fine}
                        postazione={turno.postazione}
                        servizio={turno.servizioId}
                        sedi={props.sedi}
                        servizi={props.servizi}
                        lavoratore={props.lavoratore}
                        giorno={props.giorno}
                        mattina={props.mattina}
                        action={addTurno}
                        eliminaAction={elimnaTurno}
                        close={close}
                        lavoratori={props.lavoratori}
                        lavoratoreDisable={props.lavoratoreDisable}
                        postazioneDisable={props.postazioneDisable}
                        sedeDisable={props.sedeDisable}
                    />
            }
        </Popup>
    }


    const getRipetizioneGiornoPopup = (turno, cssClass, index) => {
        return <Popup
            className='dimensione'
            ref={ref}
            trigger={<button className={`${cssClass}`} id={index +'-ripeti'}>Ripeti</button>}
            modal>{
                close => (
                    <RipetiGiorno
                        turno = {turno}
                        settimana = {props.settimana}
                        mattina = {props.mattina}
                        closeTooltip = {closeTooltip}
                        close = {close}
                        aggiungiTurnoRipetuto = {props.aggiungiTurnoRipetuto}
                        setMessage = {props.setMessage}                        
                    />)
            }
        </Popup>
    }

    return (<>
        {getPopup(<FaPlus />, { id: "", inizio: null, fine: null }, 'aggiungiTurno', indexAggiungi)}
        {props.turni.map((turno, keyTurno) => (
            <div className="row" key={`${indexTurno}-${keyTurno}`}>
                <div className='turno' key={`${indexTurno}-${keyTurno}-popup`}>{
                    getPopup(formatTurno(turno), turno, 'triggerTurno', `${indexTurno}-${keyTurno}`)
                }</div>

                <div className='turno' key={`${indexTurno}-${keyTurno}-ripeti`}>{
                    getRipetizioneGiornoPopup(turno, 'ripetiTurno', `${indexTurno}-${keyTurno}`)
                }</div>
            </div>))}
    </>)
}
