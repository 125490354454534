import moment from "moment";
import 'moment/locale/it'
moment.locale('it');

export function getStartOfWeek (number) {
        var currentYear = new Date().getFullYear().toString()
        var startofweek = moment(currentYear).add(number, 'weeks').startOf('week').format('LL')
        return startofweek
}

export function getEndOfWeek (number) {
        var currentYear = new Date().getFullYear().toString()
        var startofweek = moment(currentYear).add(number, 'weeks').endOf('week').format('LL')
        return startofweek
}

function getDaysOfWeek () {
        var week = moment(new Date()).isoWeek()
        var startIndex = - (week + 52)
        var settimane = []
        for (var i = startIndex; i <= 104; i++) {
                var settimana = {}
                settimana.label = getStartOfWeek(i) + " - " + getEndOfWeek(i)
                settimana.value = getStartOfWeek(i)
                settimane.push(settimana)
        }
        return settimane
} 

export const Settimane = getDaysOfWeek()