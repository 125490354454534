import React from 'react';
import { Table, Collapse } from 'react-bootstrap'

function Tabella(props) {
    var titleTable;
    var numeri730;
    var numeriIsee;


    if (props.fixed !== true) {
        titleTable =
            <tr>
                <th className={props.specialeTitle + " fixtitle"} colSpan={props.intestazione.length}>
                    <p className="text-center titleRow">{props.nomeTabella}</p>
                </th>
            </tr>
    } else {
        titleTable = 
        <tr>
            <th className="thFixed" colSpan={props.intestazione.length}>
                <p className="text-center titleRowFixed">{props.nomeTabella}</p>
            </th>
        </tr>
    }
    if (props.numeri730 != null) {
        numeri730 = <div className="row">
                        <div className="col numeri730Label">numero 730 stampati : {props.numeri730.stampati}</div>
                        <div className="col numeri730Label">numero 730 in modifica : {props.numeri730.inmodifica}</div>
                        <div className="col numeri730Label">numero 730 totali : {props.numeri730.totali}</div>
                    </div>
    }
    if (props.numeriIsee != null) {
        numeriIsee = <div className="row">
                        <div className="col numeri730Label">numero ISEE stampati : {props.numeriIsee.stampati}</div>
                        <div className="col numeri730Label">numero ISEE in modifica : {props.numeriIsee.inmodifica}</div>
                        <div className="col numeri730Label">numero ISEE totali : {props.numeriIsee.totali}</div>
                    </div>
    }
    return (
        <div>
            <Collapse in={props.open}>
                <div>
                    {numeri730}
                    {numeriIsee}
                    <Table striped bordered hover>
                        <thead key={props.nomeTabella}>
                            {titleTable}
                            <tr>
                                {props.intestazione}
                            </tr>
                        </thead>
                        {props.rows.map((riga, key) => {
                            return (
                                <tbody key={key}>
                                    <tr>
                                        {riga}
                                    </tr>
                                </tbody>
                            );
                        })}
                    </Table>
                </div>
            </Collapse>
        </div>
    )
}

export default Tabella;