import moment from "moment";
import 'moment/locale/it'
moment.locale('it');

function getWeekDate(number, type) {
        var startOfYear = moment().startOf('year');
        if (startOfYear.isoWeekday() !== 1) {
                startOfYear.subtract(startOfYear.isoWeekday() - 1, 'days');
        }
        var weekDate = startOfYear.add(number - 1, 'weeks');
        var result = type === 'start'
                ? weekDate.startOf('week').format('LL')
                : weekDate.endOf('week').format('LL');

        return result;
}

export function getStartOfWeek(number) {
        return getWeekDate(number, 'start');
}

export function getEndOfWeek(number) {
        return getWeekDate(number, 'end');
}

function getDaysOfWeek() {
        var settimane = []
        for (var i = 1; i <= 53; i++) {
                var settimana = {}
                settimana.label = "settimana " + i + " (" + getStartOfWeek(i) + " - " + getEndOfWeek(i) + ")"
                settimana.value = i.toString()
                settimane.push(settimana)
        }
        return settimane
}

export const Settimane = getDaysOfWeek()