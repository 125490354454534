import React, { useState, useEffect } from 'react';
import TabellaCollassabile from './TabellaCollassabile';
import { getDatiFattura } from '../handlers/datiFatturaHandler';
import { SpinnerComp } from '../helper/spinner';
import Talk from '../helper/Talk';
import { Container, Form} from 'react-bootstrap'
import { buildUrl } from "../helper/handler"
import { getFromApi } from '../utilities/NetUtilities';

function Tabella730(props) {
    const [dati, setDati] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("");
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("");
    const [checked, setChecked] = useState(false);
    const [larghezza, setLarghezza] = useState("100%");

    const handleAllYears = (e) => {
        setLoading(true);
        setChecked(!checked);
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"))
        connessioneTabella730(organizzazioneMap.nome, organizzazioneMap.tipo, !checked)
    }
    
    const connessioneTabella730 = (organizzazioneNome, tipo, tutti) => {
        const okAction = (data) => {
            setLoading(false);
            const {table, errore} = getDatiFattura( data.dati, 
                           props.format, 
                           props.type, 
                           props.nome, 
                           props.numero730inmodifica,
                            organizzazioneNome, 
                            tipo, 
                            tutti);
            setDati(table);
            setError(errore);
        }
        const nokAction = (data) => {
            setError(data.messaggi[0].descrizione);          
        }
        const url = getUrl730(organizzazioneNome, tipo, tutti)
        console.log(url)
        getFromApi(url, okAction, nokAction);
    }

    useEffect(() => {
        setLoading(true);
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        setNomeOrganizzazione(organizzazioneMap.nome);
        setTipoOrganizzazione(organizzazioneMap.tipo);
        if (organizzazioneMap) {
            connessioneTabella730(organizzazioneMap.nome, organizzazioneMap.tipo, false);
        }
    }, []);


    const getUrl730 = (organizzazioneNome, tipo, tutti) => {
        var params = {
            organizzazione: organizzazioneNome,
            tipo: tipo,
            tutti: tutti
        };
        return buildUrl(props.url, params);
    };

    const updateLarghezza = () => {
        if (!props.type.startsWith("riepilogo")) {
            setLarghezza("613%");
        }
    };
    
        if (loading) {
            return <SpinnerComp />
        }
        return(
            <div className='main'>
                <Container fluid>
                    <div style={{width: `${larghezza}` }}>
                        <div className="divPaginaTitoleFisso" style={{width:"900px"}}>
                            <h1 className="text-center">{props.titolo} ({nomeOrganizzazione.toLowerCase()}/{tipoOrganizzazione.toLowerCase()})
                            </h1>
                        </div>
                    </div>
                    <Talk errori={error}/>
                    <div className='mt-5'>
                        {/* <Button className='btnGenerale mb-3' size='sm'
                        variant="" onClick={this.handleAllYears}>tutti</Button> */}
                            <div style={{width: `${larghezza}` }}>
                                <div className='scrollAllyear'>
                                <Form.Check 
                                    inline
                                    checked={checked}
                                    className='ml-2 allYearsCheckbox mb-3'
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="mostrare tutti gli anni"
                                    onChange={ e => handleAllYears(e)}
                                    ></Form.Check>
                                </div>
                            </div>
                        {dati.map((dati, key)=> (
                            <div className="row" key={key}>
                                <TabellaCollassabile 
                                numeri730 = {dati.numeri730}
                                numeriIsee = {dati.numeriIsee}
                                rows={dati.rows} 
                                intestazione= {dati.intestazione} 
                                nomeTabella={dati.name}
                                button = {dati.button}
                                setLarghezza={updateLarghezza}
                                type={props.type}/>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>)
    }

export default Tabella730